// Import CSS
import '../bootstrap/css/bootstrap.min.css';
import '../fonts/fontawesome-all.min.css';
import '../css/Mega-Menu-Dropdown-100-Editable---Ambrodu.css';
import '../css/gijgo.min.css';
import '../css/theme.default.min.css';
import '../css/theme.dark.min.css';
import '../css/theme.bootstrap_4.min.css';
import './addons/pager/jquery.tablesorter.pager.css';
import '../css/lightbox.rotate.img.css';
import '../css/styles.css';
// Import JS
import '../bootstrap/js/bootstrap.min.js';
import './chart.min.js';
import './jquery.easing.min.js';
import './theme.js';
import './jquery.html5storage.min.js';
import './gijgo.fr.min.js';
// impor '!!raw-loader!.//gijgo.fr.min.txt'; // Adding `!!` to a request will disable all loaders specified in the configuration
import './jquery.tablesorter.min.js';
import './jquery.tablesorter.widgets.min.js';
import './widget-cssStickyHeaders.min.js';
import './addons/pager/jquery.tablesorter.pager.min.js';
import './lightbox.rotate.img.js';
import './smooth.scroll.anchor.js';
// import './all.table.exports.js';
// import './xlsx.core.min.js';
// import './Blob.min.js';
import './FileSaver.min.js';
import 'tableexport';
// import './tableexport.min.js';

var globals,
App = {

	settings: {
		defLatLng: [48.86, 2.33],
		defZoom: 6,
		bottomScrolled: false,
		getFilter: false,
		myEvent: 'loadEvent',
		myPage: '',
		isMobile: false,
		serverAddress: "https://oxy.resosign.fr/db.php",
		serverAddressDashboard: "https://oxy.resosign.fr/db_dashboard.php",
		serverAddressWorker: "https://oxy.resosign.fr/db_workers.php",
		serverAddressCampaign: "https://oxy.resosign.fr/db_campaigns.php",
		serverAddressClient: "https://oxy.resosign.fr/db_clients.php",
		serverAddressAdministratives: "https://oxy.resosign.fr/db_administratives.php",
		serverAddressCommercial: "https://oxy.resosign.fr/db_commercial.php",
		year: new Date().getFullYear(),
		today: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
		currentCampainId: 0,
		pass: $.localStorage.getItem('pass'),
		login: $.localStorage.getItem('login'),
		pwd: $.localStorage.getItem('pwd'),
		name: $.localStorage.getItem('name'),
		phone: $.localStorage.getItem('phone'),
		calendar: $.localStorage.getItem('calendar'),
		id: $.localStorage.getItem('id'),
		active: $.localStorage.getItem('active'),
		email: $.localStorage.getItem('email'),
		type: $.localStorage.getItem('type')
	},

	refreshGlobals: function(data) {
		globals.pass = data.pass;
		globals.login = data.login;
		globals.pwd = data.pwd;
		globals.name = data.name;
		globals.phone = data.phone;
		globals.calendar = data.calendar;
		globals.id = data.id;
		globals.active = data.active;
		globals.email = data.email;
		globals.type = data.type;
	},

	init: function() {
		// kick things off
		globals = this.settings;
		this.bindUIActions();
		$("#now-date").append(globals.year);
	},

	search: function (myFormDiv) {
		$(myFormDiv+' #sender').attr("disabled", true);
		let query = $('#searchForm').serialize();
		let req = "searchDocs";
		query = query + "&req=" + req;
		let returns = "";
		//$(myFormDiv+' #successfail').append('<div class="alert alert-success" role="alert"><b>Query : '+query+'</b></div>');
		$.post(globals.serverAddress, query, function(data){
			if(data.ok=="ok") {
				returns = data.items;
				$('#scrollToResult').trigger('click');
			}
			else
				returns = '<div class="alert alert-danger" role="alert"><b>Aucun résultats !!</b></div>';
		}, "json").always(function(data){
			$(myFormDiv+' #sender').attr("disabled", false);
			$('#successfail').empty().append(returns);
		});
	},

	logMeIn: function (myFormDiv) {
		$(myFormDiv+' #sender').attr("disabled", true);
		$(myFormDiv).closest('.row').css('opacity', 0.6);
		let query = $(myFormDiv).serialize();
		const req = "login";
		query = query + "&req=" + req;
		$.post(globals.serverAddress, query, function(data){
			if(data.pass == "OK") {
//			echo json_encode(array("login"=>$login, "id"=>$userid, "name"=>$username, "type"=>$usertype, "active"=>$useractive, "email"=>$login, "pass"=>$pass, "badid"=>$badid, "pwd"=>$locker));
				$.localStorage.setItem('pass', data.pass);
				$.localStorage.setItem('login', data.login);
				$.localStorage.setItem('pwd', data.pwd);
				$.localStorage.setItem('name', data.name);
				$.localStorage.setItem('phone', data.phone);
				$.localStorage.setItem('calendar', data.calendar);
				$.localStorage.setItem('id', data.id);
				$.localStorage.setItem('email', data.email);
				$.localStorage.setItem('active', data.active);
				$.localStorage.setItem('type', data.type);
				setTimeout(function(){
					App.refreshGlobals(data);
				}, 100);
				setTimeout(function(){
					document.location.href='index.html';
				}, 1000);
			}
			else {
				if(data.active) alert("Ce compte a été désactivé !");
				else alert("Identifiant ou mot de passe erroné !");
			}
		}, "json").always(function() {
			$(myFormDiv+' #sender').attr("disabled", false);
			$(myFormDiv).closest('.row').css('opacity', 1);
		});
	},

	logMeOut: function () {
		$.localStorage.setItem('pass', false);
		$.localStorage.setItem('login', '');
		$.localStorage.setItem('pwd', '');
		$.localStorage.setItem('name', '');
		$.localStorage.setItem('phone', '');
		$.localStorage.setItem('calendar', '');
		$.localStorage.setItem('id', '');
		$.localStorage.setItem('email', '');
		$.localStorage.setItem('active', '');
		$.localStorage.setItem('type', '');
		setTimeout(function(){
			document.location.href='login.html';
		}, 1000);
	},

	subContact: function (myFormDiv) {
		$(myFormDiv+' #sender').attr("disabled", true);
		let query = $(myFormDiv).serialize();
		const req = "contact";
		query = query + "&req=" + req;
		let returns = "";
		//$(myFormDiv+' #successfail').append('<div class="alert alert-success" role="alert"><b>Query : '+query+'</b></div>');
		$.post(globals.serverAddress, query, function(data){
			if(data.ok=="ok")
				returns = '<div class="alert alert-success" role="alert"><b>Votre message a bien été envoyé.</b></div>';
			else
				returns = '<div class="alert alert-danger" role="alert"><b>Votre message n\'a pas été envoyé.</b></div>';
			$(myFormDiv+' #sender').attr("disabled", false);
			$(myFormDiv+' #successfail').empty().append(returns);
		}, "json");
	},

	subContactSns: function (myFormDiv) {
		$(myFormDiv+' #sender').attr("disabled", true);
		var query = $(myFormDiv).serialize();
		const req = "contactSns";
		query = query + "&req=" + req;
		let returns = "";
		//$(myFormDiv+' #successfail').append('<div class="alert alert-success" role="alert"><b>Query : '+query+'</b></div>');
		$.post(globals.serverAddress, query, function(data){
			if(data.ok=="ok") {
				returns = '<div class="alert alert-success" role="alert"><b>Votre message a bien été envoyé.</b></div>';
				setTimeout(function(){
					$(myFormDiv).closest('.modal').modal('hide');
				}, 1600);
			}
			else
				returns = '<div class="alert alert-danger" role="alert"><b>Votre message n\'a pas été envoyé.</b></div>';
			$(myFormDiv+' #sender').attr("disabled", false);
			$(myFormDiv+' #successfail').empty().append(returns);
		}, "json");
	},

	subManagement: function (myFormDiv) {
		$(myFormDiv+' #sender').attr("disabled", true);
		let query = $(myFormDiv).serialize();
		const req = "questions2019";
		query = query + "&req=" + req;
		let returns = "";
		var myModalId = "";
		//$(myFormDiv+' #successfail').append('<div class="alert alert-success" role="alert"><b>Query : '+query+'</b></div>');
		$.post(globals.serverAddress, query, function(data){
			if(data.ok=="ok") {
				returns = '<div class="alert alert-success" role="alert"><b>Nous avons procédé au traitement de vos réponses avec succès.</b></div>';
				alert("Nous avons procédé au traitement de vos réponses avec succès.");
			}
			else {
				returns = '<div class="alert alert-danger" role="alert"><b>Nous n\'avons pas pu procédé au traitement de vos réponses suite à un problème technique.</b></div>';
				alert("Nous n'avons pas pu procédé au traitement de vos réponses suite à un problème technique.");
			}
		}, "json").always(function(data){
			$(myFormDiv+' #sender').attr("disabled", false);
			$(myFormDiv+' #successfail').empty().append(returns);
		});
	},

	addWasValidatedClass: function (myFormDiv) {
		$(myFormDiv).addClass('was-validated');
	},

	clearFormFields: function(myFormDiv, event)
	{
		if(myFormDiv=='#editPicturesAddressForm') event.preventDefault(); // prevents form submission when button is inside it !
		$(myFormDiv).find("input[type=text], input[type=tel], input[type=email], input[type=number], input[type=file], textarea, select").val("");
		$(myFormDiv).find("input[type=checkbox]").prop("checked", false);
		$(myFormDiv+' #successfail').empty();
	},

	safeJsonParse: function(input) {
		try {
			return JSON.parse(input);
		} catch (e) {
			return undefined;
		}
	},

	urlParam: function(name, url){
		// Get parameters from an URL
		var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(url);
		//For current URL
		//var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(window.location.href);
		if (results==null){
			return null;
		}
		else{
			return results[1] || 0;
		}
	},

	bindUIActions: function() {
		$("[data-toggle=tooltip]").tooltip();
		// Is it Mobile device
		if(/Mobi/i.test(navigator.userAgent) || /Android/i.test(navigator.userAgent)) globals.isMobile = true;
		if(globals.isMobile) $('.sidebar').addClass('toggled');
		$('.expends').on("click", function () {
			$(this).next('div').slideToggle('slow');
			//$.mobile.silentScroll($(this).next('div').offset().top);
		});
		// On Enter search...
		$('#keywords').keydown(function(e) {
			var keywords = $('#keywords').val();
			if (e.keyCode === 13) {
				App.search();
			}
		});
		// Display .adminOnly, .accountantOnly... Objects
		switch (globals.type) {
			case 'admin':
				$(".adminOnly").show();
			break;
			case 'accountant':
				$(".accountantOnly").show();
				$(".accountantHide").hide();
			break;
			case 'seller':
				$(".commercialOnly").show();
				$(".commercialHide").hide();
			break;
		}
		// Managing navigation so that we bindUIActions on the right page...
		let url = window.location.pathname;
		globals.myPage = url.substring(url.lastIndexOf('/')+1);
		if (globals.myPage != 'login.html') {
			// All pages except login
			$('#userNameCont').empty().append(globals.name);
			App.getCampaignsListMenu();
			App.getCampaignsAlertMenu();
		}
		switch (globals.myPage) {
			case 'index.html':
				if(globals.type=='accountant') document.location.href='administratives.html';
				if(globals.type=='seller') document.location.href='commercial.html';
				App.getCampaignsProgressBar('onLoad');
				App.getCampaignsCardsStats();
				App.getCampaignsDoughnutStats();
				App.getCampaignsLineStats();
			break;
			case 'campaigns.html':
				App.getCampaignsList();
				$('#sidebarToggle').trigger('click');
				App.generateUserSelect('#modFormCampaigns');
				App.generateClientSelect('#addFormCampaigns');
			break;
			case 'workers.html':
				App.getWorkersList();
				$('#sidebarToggle').trigger('click');
			break;
			case 'clients.html':
				App.getClientsList();
				//$('#sidebarToggle').trigger('click');
			break;
			case 'administratives.html':
				if(globals.type == 'accountant' || globals.type == 'admin') {
					App.getWorkersDocsList();
					$('#sidebarToggle').trigger('click');
				}
				else {
					alert("Zone réservée à la compta !");
					document.location.href='index.html';
				}
			break;
			case 'commercial.html':
				if(globals.type == 'seller' || globals.type == 'admin') {
					App.getProspectsList();
					App.getAppointmentsList();
					$('#sidebarToggle').trigger('click');
					$('#addFormProspects #date_first_pr').datepicker({
						locale: 'fr-fr',
						format: 'dd/mm/yyyy',
						minDate: globals.today,
						modal: true,
						showOtherMonths: true,
						iconsLibrary: 'fontawesome',
						uiLibrary: 'bootstrap4'
						//uiLibrary: 'bootstrap'
					});
					$('#addFormProspects #date_last_pr').datepicker({
						locale: 'fr-fr',
						format: 'dd/mm/yyyy',
						minDate: globals.today,
						modal: true,
						showOtherMonths: true,
						iconsLibrary: 'fontawesome',
						uiLibrary: 'bootstrap4'
						//uiLibrary: 'bootstrap'
					});
					$('#addFormProspects #date_next_pr').datepicker({
						locale: 'fr-fr',
						format: 'dd/mm/yyyy',
						minDate: globals.today,
						modal: true,
						showOtherMonths: true,
						iconsLibrary: 'fontawesome',
						uiLibrary: 'bootstrap4'
						//uiLibrary: 'bootstrap'
					});
					$('#modFormProspects #date_first_pr').datepicker({
						locale: 'fr-fr',
						format: 'dd/mm/yyyy',
						minDate: globals.today,
						modal: true,
						showOtherMonths: true,
						iconsLibrary: 'fontawesome',
						uiLibrary: 'bootstrap4'
						//uiLibrary: 'bootstrap'
					});
					$('#modFormProspects #date_last_pr').datepicker({
						locale: 'fr-fr',
						format: 'dd/mm/yyyy',
						minDate: globals.today,
						modal: true,
						showOtherMonths: true,
						iconsLibrary: 'fontawesome',
						uiLibrary: 'bootstrap4'
						//uiLibrary: 'bootstrap'
					});
					$('#modFormProspects #date_next_pr').datepicker({
						locale: 'fr-fr',
						format: 'dd/mm/yyyy',
						minDate: globals.today,
						modal: true,
						showOtherMonths: true,
						iconsLibrary: 'fontawesome',
						uiLibrary: 'bootstrap4'
						//uiLibrary: 'bootstrap'
					});
					$('#addAppointment #date_start_pe').datetimepicker({
						locale: 'fr-fr',
						format: 'dd/mm/yyyy HH:MM',
						minDate: globals.today,
						modal: true,
						footer: true,
						datepicker: { showOtherMonths: true, calendarWeeks: false },
						iconsLibrary: 'fontawesome',
						uiLibrary: 'bootstrap4'
						//uiLibrary: 'bootstrap'
					});
					$('#addAppointment #date_end_pe').datetimepicker({
						locale: 'fr-fr',
						format: 'dd/mm/yyyy HH:MM',
						minDate: globals.today,
						modal: true,
						footer: true,
						datepicker: { showOtherMonths: true, calendarWeeks: false },
						iconsLibrary: 'fontawesome',
						uiLibrary: 'bootstrap4'
						//uiLibrary: 'bootstrap'
					});
					$('#modAppointment #date_start_pe').datetimepicker({
						locale: 'fr-fr',
						format: 'dd/mm/yyyy HH:MM',
						minDate: globals.today,
						modal: true,
						footer: true,
						datepicker: { showOtherMonths: true, calendarWeeks: false },
						iconsLibrary: 'fontawesome',
						uiLibrary: 'bootstrap4'
						//uiLibrary: 'bootstrap'
					});
					$('#modAppointment #date_end_pr').datetimepicker({
						locale: 'fr-fr',
						format: 'dd/mm/yyyy HH:MM',
						minDate: globals.today,
						modal: true,
						footer: true,
						datepicker: { showOtherMonths: true, calendarWeeks: false },
						iconsLibrary: 'fontawesome',
						uiLibrary: 'bootstrap4'
						//uiLibrary: 'bootstrap'
					});
				}
				else {
					alert("Zone réservée au commerce !");
					document.location.href='index.html';
				}
			break;
			case 'calendar.html':
				$('#sidebarToggle').trigger('click');
				setInterval(function(){
					document.getElementById('calendarFrame').src += '';
				}, 300000);
				if(globals.type == 'seller') {
					$('#calendarFrame').attr('src', 'https://calendar.google.com/calendar/embed?height=600&wkst=2&bgcolor=%23F6BF26&ctz=Europe%2FParis&src=cjIxNXF2dG90Y3BmbjNrcThhaG5rdDRmaGtAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&src=ZnIuZnJlbmNoI2hvbGlkYXlAZ3JvdXAudi5jYWxlbmRhci5nb29nbGUuY29t&color=%23F6BF26&color=%2333B679&showTz=0&title=Commercial');
				}
			break;
			case 'galery.html':
				// Focus
				$('#keywords').focus();
			break;
			case 'login.html':
			break;
			default:
				// Default also works for index.html when it's empty in the address bar
				if(globals.type=='accountant') document.location.href='administratives.html';
				if(globals.type=='seller') document.location.href='commercial.html';
				App.getCampaignsProgressBar('onLoad');
				App.getCampaignsCardsStats();
				App.getCampaignsDoughnutStats();
				App.getCampaignsLineStats();
		}
		// Add the following code if you want the name of the file appear on select
		$(".custom-file-input").on("change", function(e) {
			//const fileName = $(this).val().split("\\").pop();
			let fileName = "";
			//e.target.files.forEach(function(item){ fileName += '"'+item.name+'"';});
			//alert(e.target.files[0].name);
			for (var i = 0; i < e.target.files.length; i++) {
				fileName += '"'+e.target.files[i].name+'" ';
			}
			$(this).siblings(".custom-file-label").addClass("selected").html(fileName);
		});
		// Fix MegaMenu scroll on mobile and small screens (same way BS4 Modals do)
		$('.megamenu .dropdown-toggle').on("click", function() {
			setTimeout(function(){
				if($('.megamenu .dropdown-menu').is(':hidden')) {
					$('body').removeClass('modal-open');
				}
				else {
					$('body').addClass('modal-open');
				}
			}, 600);
		});
		$('body').on("click", function(event) {
			if(event) {
				if (!$(event.target).closest('a').length && !$(event.target).closest('button').length && !$(event.target).closest('.modal').length && $(event.target).closest('.gj-modal').is(':hidden')) {
					setTimeout(function(){
						if($('.megamenu .dropdown-menu').is(':hidden')) {
							$('body').removeClass('modal-open');
						}
					}, 600);
				}
			}
		});
		$('#collapseFourModBtn').on("click", function() {
			App.getCampaignsBiList(globals.currentCampainId);
		});
		document.addEventListener("scroll", function (event) {
			if (App.getDocHeight() == App.getScrollXY()[1] + window.innerHeight) {
				//$('.go-up-fixed').fadeOut('slow');
				$('#topNavbar').addClass('fixed-top');
				$('.sidebar').addClass('toggled');
				globals.bottomScrolled=true;
			}
			else {
				globals.bottomScrolled=false;
				if(App.getScrollXY()[1] == 0) {
					$('#topNavbar').removeClass('fixed-top');
					$('.sidebar').removeClass('toggled');
					//$('.go-up-fixed').fadeOut('slow');
				}
				else {
					$('#topNavbar').addClass('fixed-top');
					$('.sidebar').addClass('toggled');
				}
			}
		});
		/*
		var supportOffset = window.pageYOffset !== undefined,
		lastKnownPos = 0,
		ticking = false,
		scrollDir;
		window.addEventListener('wheel', function(e) {
			if($('#zoomThis').is(':visible')) {
				currYPos = supportOffset ? window.pageYOffset : document.body.scrollTop;
				scrollDir = lastKnownPos > currYPos ? 'up' : 'down';
				lastKnownPos = currYPos;

				if (!ticking) {
					window.requestAnimationFrame(function() {
						App.zoomThisInOrOut(lastKnownPos, scrollDir);
						ticking = false;
					});
				}
				ticking = true;
			}
		});
		// Continuous search...
		$('#keywords').keyup(function() {
			var keywords = $('#keywords').val();
			if(keywords.length > 6) {
				App.search();
			} else {
				// doNothing !
			}
		});
		*/
	},

	zoomThisInOrOut: function (scrollPos, scrollDir) {
		// Your code goes here...
		console.log('scroll pos: ' + scrollPos + ' | scroll dir: ' + scrollDir);
		if (scrollDir == "up"){
			let currWidth = $("#zoomThis").width();
			let	newWidth = currWidth + 100;
			$("#zoomThis").width(newWidth + "px");
			$(".lb-outerContainer").width(newWidth + "px");
			$(".lb-dataContainer").width(newWidth + "px");
			console.log('Up: ' + currWidth);
		}
		else {
			let currWidth = $("#zoomThis").width();
			let	newWidth = currWidth - 100;
			$("#zoomThis").width(newWidth + "px");
			$(".lb-outerContainer").width(newWidth + "px");
			$(".lb-dataContainer").width(newWidth + "px");
			console.log('Down: ' + currWidth);
		}
	},

	getCampaignsListMenu: function()
	{
		let req = "getCampaignsListMenu";
		const query = "&id=" + globals.id + "&type=" + globals.type + "&pwd=" + globals.pwd + "&req=" + req;
		$.post(globals.serverAddressDashboard, query, function(data){
			$("#lastCampainsMenuContPlay").empty().append(data.play);
			$("#lastCampainsMenuContStop").empty().append(data.stop);
		}, "json").always(function(data){
			// Refreshing alerts every now and then...
			setTimeout(function(){
				App.getCampaignsListMenu();
			}, 300000);
		});
	},

	getCampaignsAlertMenu: function()
	{
		let req = "getCampaignsAlertMenu";
		const query = "&id=" + globals.id + "&type=" + globals.type + "&pwd=" + globals.pwd + "&req=" + req;
		$.post(globals.serverAddressDashboard, query, function(data){
			$("#campainsAlertMenuBadge").empty().append(data.badge);
			$("#campainsAlertMenuCont").empty().append(data.snippet);
		}, "json").always(function(data){
			// Refreshing alerts every now and then...
			setTimeout(function(){
				App.getCampaignsAlertMenu();
			}, 60000);
		});
	},

	getCampaignsProgressBar: function(caller)
	{
		if(caller=="onLoad") {
			const query = "&id=" + globals.id + "&type=" + globals.type + "&pwd=" + globals.pwd + "&req=" + "getCampaignsProgressBar";
			$.post(globals.serverAddressDashboard, query, function(data){
				$("#campaignsProgressBarCont").empty().append(data.snippet);
				$('#startdate_bars').datepicker({
					locale: 'fr-fr',
					format: 'yyyy-mm-dd',
					minDate: globals.today,
					modal: true,
					showOtherMonths: true,
					iconsLibrary: 'fontawesome',
					uiLibrary: 'bootstrap4'
					//uiLibrary: 'bootstrap'
				});
			}, "json");
		}
		else {
			var askedUser = $('#user_id_bars').val();
			var askedStatus = $('#status_bars').val();
			var outOfDate = $('#outofdate_bars').val();
			var startDate = $('#startdate_bars').val();
			const query = "&user_id=" + askedUser + "&status=" + askedStatus + "&out_of_date=" + outOfDate + "&startDate=" + startDate + "&id=" + globals.id + "&type=" + globals.type + "&pwd=" + globals.pwd + "&req=" + "getCampaignsProgressBar";
			$.post(globals.serverAddressDashboard, query, function(data){
				$("#campaignsProgressBarCont").empty().append(data.snippet);
				$('#user_id_bars').val(askedUser);
				$('#status_bars').val(askedStatus);
				$('#outofdate_bars').val(outOfDate);
				if(startDate) {
					const dateParts = startDate.split("-");
					const showSelectedDate = dateParts[2]+"/"+dateParts[1]+"/"+dateParts[0];
					$('#startdate_bars_label').empty().append("Sélection: "+ showSelectedDate);
				}
				$('#startdate_bars').datepicker({
					locale: 'fr-fr',
					format: 'yyyy-mm-dd',
					minDate: globals.today,
					modal: true,
					showOtherMonths: true,
					iconsLibrary: 'fontawesome',
					uiLibrary: 'bootstrap4'
					//uiLibrary: 'bootstrap'
				});
			}, "json");
		}
	},

	setCurrentCampaignName: function(name)
	{
		$('.breadcrumb-item .currentCampaign').each(function(index) {
			$(this).empty().append(name);
		});
	},

	filterProgressBarOutOfDate: function()
	{
		$('#outofdate_bars').val(1);
		App.getCampaignsProgressBar();
	},

	getCampaignsCardsStats: function(caller)
	{
		const query = "&id=" + globals.id + "&type=" + globals.type + "&pwd=" + globals.pwd + "&req=" + "getCampaignsCardsStats";
		$.post(globals.serverAddressDashboard, query, function(data){
			$("#campsOpenClosedCont").empty().append(data.snippet_1);
			$("#campsClosedCont").empty().append(data.snippet_2);
			$("#campsOutOfDateCont").empty().append(data.snippet_3);
			$("#campsClosedCancelCont").empty().append(data.snippet_4);
			/*
			// doughnut
			{
				'type':'doughnut',
				'data':{
					'labels':['Valides','Invalides'],
					'datasets':[
						{
							'label':'',
							'backgroundColor':['#36b9cc','#f6c23e'],
							'borderColor':['#ffffff','#ffffff','#ffffff'],
							'data':[50,30]
						}
					]
				},
				'options':{'maintainAspectRatio':false,'legend':{'display':false},'title':{}}
			}
			// line
			{
				'type':'line',
				'data':{
					'labels':['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug'],
					'datasets':[
						{
							'label':'Earnings',
							'fill':true,
							'data':['0','10000','5000','15000','10000','20000','15000','25000'],
							'backgroundColor':'rgba(78, 115, 223, 0.05)',
							'borderColor':'rgba(78, 115, 223, 1)'
						}
					]
				},
				'options':{
					'maintainAspectRatio':false,
					'legend':{'display':false},
					'title':{},
					'scales':{
						'xAxes':[
							{
								'gridLines':{
									'color':'rgb(234, 236, 244)',
									'zeroLineColor':'rgb(234, 236, 244)',
									'drawBorder':false,
									'drawTicks':false,
									'borderDash':['2'],
									'zeroLineBorderDash':['2'],
									'drawOnChartArea':false
								},
								'ticks':{'fontColor':'#858796','padding':20}
							}
						],
						'yAxes':[
							{
								'gridLines':{
									'color':'rgb(234, 236, 244)',
									'zeroLineColor':'rgb(234, 236, 244)',
									'drawBorder':false,
									'drawTicks':false,
									'borderDash':['2'],
									'zeroLineBorderDash':['2']
								},
								'ticks':{'fontColor':'#858796','padding':20}
							}
						]
					}
				}
			}
			*/
		}, "json");
	},

	getCampaignsDoughnutStats: function(caller)
	{
		const query = "&id=" + globals.id + "&type=" + globals.type + "&pwd=" + globals.pwd + "&req=" + "getCampaignsDoughnutStats";
		$.post(globals.serverAddressDashboard, query, function(data){
			const myDoughnutChart = new Chart($("#picturesManagementDoughnut"), {
				type: 'doughnut',
				data: {
					'labels':['Valides','Invalides','Non vues'],
					'datasets':[
						{
							'backgroundColor':['#36b9cc','#f6c23e','#858796'],
							'borderColor':['#ffffff','#ffffff','#ffffff'],
							'data': data.doughnut
						}
					]
				},
				options: {
					'maintainAspectRatio':false,
					'legend':{'display':false},
					tooltips: {
						callbacks: {
							label: function(tooltipItem, data) {
								var dataset = data.datasets[tooltipItem.datasetIndex];
								var meta = dataset._meta[Object.keys(dataset._meta)[0]];
								var total = meta.total;
								var currentValue = dataset.data[tooltipItem.index];
								//var percentage = parseFloat((currentValue/total*100).toFixed(1));
								//return currentValue + ' (' + percentage + '%)';
								return currentValue + ' %';
							},
							title: function(tooltipItem, data) {
								return data.labels[tooltipItem[0].index];
							}
						}
					},
					'title':{}
				}
			});
		}, "json");
	},

	getCampaignsLineStats: function(caller)
	{
		const query = "&id=" + globals.id + "&type=" + globals.type + "&pwd=" + globals.pwd + "&req=" + "getCampaignsLineStats";
		$.post(globals.serverAddressDashboard, query, function(data){
			$('.chart-area .loading').hide();
			const myLineChart = new Chart($("#picturesManagementLineChart"), {
				'type':'line',
				'data':{
					'labels':data.lineLabels,
					'datasets':[
						{
							'label':'Photos reçues',
							'fill':true,
							'data':data.lineDataDocs,
							'backgroundColor':'rgba(78, 115, 223, 0.05)',
							'borderColor':'rgba(78, 115, 223, 1)'
						},
						{
							'label':'BI reçus',
							'fill':true,
							'data':data.lineDataBi,
							'backgroundColor':'#EDC459',
							'borderColor':'#f6c23e'
						}
					]
				},
				'options':{
					'responsive': true,
					'maintainAspectRatio':false,
					'legend':{'display':true},
					'title':{},
					'scales':{
						'xAxes':[
							{
								'gridLines':{
									'color':'rgb(234, 236, 244)',
									'zeroLineColor':'rgb(234, 236, 244)',
									'drawBorder':false,
									'drawTicks':false,
									'borderDash':['2'],
									'zeroLineBorderDash':['2'],
									'drawOnChartArea':false
								},
								'ticks':{'fontColor':'#858796','padding':20}
							}
						],
						'yAxes':[
							{
								'stacked': false,
								'gridLines':{
									'color':'rgb(234, 236, 244)',
									'zeroLineColor':'rgb(234, 236, 244)',
									'drawBorder':false,
									'drawTicks':false,
									'borderDash':['2'],
									'zeroLineBorderDash':['2']
								},
								'ticks':{'fontColor':'#858796','padding':20}
							}
						]
					}
				}
			});
		}, "json");
	},

	searchWorker: function(myFormDiv)
	{
		$(myFormDiv+' #sender').attr("disabled", true);
		let query = $(myFormDiv).serialize();
		let req = "searchWorker";
		query = query + "&id=" + globals.id + "&pwd=" + globals.pwd + "&req=" + req;
		$.post(globals.serverAddressWorker, query, function(data){
			if(data.ok=="ok") {
				$("#tabWorkersCont").empty().append(data.snippet);
				App.smoothScrollTo('#tabWorkersCont', 80);
				$('.tablesorterWorkers').tablesorter({
					theme: 'bootstrap', // dark or bootstrap are cool here.
					dateFormat : "ddmmyyyy", // set the default date format
					// hidden filter input/selects will resize the columns, so try to minimize the change
					widthFixed : true,
					// initialize zebra striping and filter widgets
					//widgets: ["zebra", "filter"],
					widgets: ["filter", "cssStickyHeaders"],
					ignoreCase: false,
					widgetOptions : {
						// See options at : https://mottie.github.io/tablesorter/docs/example-widget-filter.html
						filter_childRows : false,
						filter_columnFilters : true,
						filter_cellFilter : '',
						filter_defaultFilter : {},
						filter_excludeFilter : {},
						filter_external : '',
						filter_filteredRow : 'filtered',
						filter_formatter : null,
						filter_functions : null,
						filter_hideEmpty : true,
						filter_hideFilters : false,
						filter_ignoreCase : true,
						filter_liveSearch : true,
						filter_startsWith : true,
						filter_onlyAvail : 'filter-onlyAvail',
						filter_placeholder : { search : 'Filtre', select : '' },
						filter_reset : 'button.reset',
						filter_saveFilters : false,
						filter_searchDelay : 300,
						filter_searchFiltered: true,
						filter_selectSource  : null,
						filter_serversideFiltering : false,
						filter_useParsedData : false,
						filter_defaultAttrib : 'data-value',
						filter_selectSourceSeparator : '|',
						// if false and a caption exist, it won't be included in the sticky header
						cssStickyHeaders_addCaption: true,
						// jQuery selector or object to attach sticky header to
						cssStickyHeaders_attachTo: null,
						// number or jquery selector targeting the position:fixed element
						cssStickyHeaders_offset: 70,
						// scroll table top into view after filtering
						cssStickyHeaders_filteredToTop : true,
						// extra css class name (string or array) added to the filter element (input or select)
						filter_cssFilter: [
							'form-control',
							'form-control',
							'form-control',
							'form-control',
							'form-control custom-select', // select needs custom class names :(
							'form-control custom-select', // select needs custom class names :(
							'form-control',
							'form-control',
							'form-control',
							'form-control custom-select', // select needs custom class names :(
							'form-control',
							'form-control',
							'form-control',
							'form-control',
						]
					}
				}).tablesorterPager({
					container: $('#workersPager'),
					size: 20
				});
			}
			else alert("Il n'y a pas de résultat pour cette recherche !");
		}, "json").always(function(data){
			$(myFormDiv+' #sender').attr("disabled", false);
		});
	},

	getWorkersList: function() {
		$.post(globals.serverAddressWorker, {id: globals.id, type: globals.type, pwd: globals.pwd, req: 'getWorkersList'}, function(data){ 
			$("#tabWorkersCont").empty().append(data.snippet);
			//$.localStorage.setItem('workers', data.workers);
			//globals.workers = data.workers;
		}, "json").done(function() {
			$('.tablesorterWorkers').tablesorter({
				theme: 'bootstrap', // dark or bootstrap are cool here.
				dateFormat : "ddmmyyyy", // set the default date format
				// hidden filter input/selects will resize the columns, so try to minimize the change
				widthFixed : true,
				// initialize zebra striping and filter widgets
				// widgets: ["zebra", "filter"],
				widgets: ["filter", "cssStickyHeaders"],
				ignoreCase: false,
				widgetOptions : {
					// See options at : https://mottie.github.io/tablesorter/docs/example-widget-filter.html
					filter_childRows : false,
					filter_columnFilters : true,
					filter_cellFilter : '',
					filter_defaultFilter : {},
					filter_excludeFilter : {},
					filter_external : '',
					filter_filteredRow : 'filtered',
					filter_formatter : null,
					filter_functions : null,
					filter_hideEmpty : true,
					filter_hideFilters : false,
					filter_ignoreCase : true,
					filter_liveSearch : true,
					filter_startsWith : true,
					filter_onlyAvail : 'filter-onlyAvail',
					filter_placeholder : { search : 'Filtre', select : '' },
					filter_reset : 'button.reset',
					filter_saveFilters : false,
					filter_searchDelay : 300,
					filter_searchFiltered: true,
					filter_selectSource  : null,
					filter_serversideFiltering : false,
					filter_useParsedData : false,
					filter_defaultAttrib : 'data-value',
					filter_selectSourceSeparator : '|',
					// if false and a caption exist, it won't be included in the sticky header
					cssStickyHeaders_addCaption: true,
					// jQuery selector or object to attach sticky header to
					cssStickyHeaders_attachTo: null,
					// number or jquery selector targeting the position:fixed element
					cssStickyHeaders_offset: 70,
					// scroll table top into view after filtering
					cssStickyHeaders_filteredToTop : true,
					// extra css class name (string or array) added to the filter element (input or select)
					filter_cssFilter: [
						'form-control',
						'form-control',
						'form-control',
						'form-control',
						'form-control custom-select', // select needs custom class names :(
						'form-control custom-select', // select needs custom class names :(
						'form-control',
						'form-control',
						'form-control',
						'form-control custom-select', // select needs custom class names :(
						'form-control',
						'form-control',
						'form-control',
						'form-control',
					]
				}
			}).tablesorterPager({
				container: $('#workersPager'),
				size: 20
			});
		});
	},

	fillModWorker: function(auto_p, name_p, login_p, pwd_p, app_p, color_p, contract_p, address_p, zip_p, city_p, country_p, address_del_p, contact_p, phone_p, phone2_p, mail2_p, maker_p, adh_p, renov_p, solar_p, deco_p, notaint_p, wallpaper_p, total_cov_p, semi_cov_p, letter_logo_p, pvc_plexi_p, bache_p, toile_p, bache_xxl_p, sign_nolight_p, sign_light_p, plv_p, totem_p, nacelle_p, elec_p, echaf_p, cord_p, eq_caces_p, eq_secu_p, eq_utilitaire_p, eq_local_p, eq_stock_p, eq_nacelle_p, eq_echaf_p, formation_p, zone_inter_p, comments_p, date_creation_p, date_mod_p)
	{
		App.clearFormFields('#modFormWorkers');
		$('#modFormWorkers #auto_p').val(auto_p);
		$('#modFormWorkers #name_p').val(name_p);
		$('#modFormWorkers #login_p').val(login_p);
		$('#modFormWorkers #pwd_p').val(pwd_p);
		if(app_p!=0) app_p = 1;
		$('#modFormWorkers #app_p').val(app_p);
		$('#modFormWorkers #color_p').val(color_p);
		$('#modFormWorkers #contract_p').val(contract_p);
		$('#modFormWorkers #address_p').val(address_p);
		$('#modFormWorkers #zip_p').val(zip_p);
		$('#modFormWorkers #city_p').val(city_p);
		$('#modFormWorkers #country_p').val(country_p);
		$('#modFormWorkers #address_del_p').val(address_del_p);
		$('#modFormWorkers #contact_p').val(contact_p);
		$('#modFormWorkers #phone_p').val(phone_p);
		$('#modFormWorkers #phone2_p').val(phone2_p);
		$('#modFormWorkers #mail2_p').val(mail2_p);
		if(maker_p=='1') $('#modFormWorkers #maker_p').prop("checked", true);
		if(adh_p=='1') $('#modFormWorkers #adh_p').prop("checked", true);
		if(renov_p=='1') $('#modFormWorkers #renov_p').prop("checked", true);
		if(solar_p=='1') $('#modFormWorkers #solar_p').prop("checked", true);
		if(deco_p=='1') $('#modFormWorkers #deco_p').prop("checked", true);
		if(notaint_p=='1') $('#modFormWorkers #notaint_p').prop("checked", true);
		if(wallpaper_p=='1') $('#modFormWorkers #wallpaper_p').prop("checked", true);
		if(total_cov_p=='1') $('#modFormWorkers #total_cov_p').prop("checked", true);
		if(semi_cov_p=='1') $('#modFormWorkers #semi_cov_p').prop("checked", true);
		if(letter_logo_p=='1') $('#modFormWorkers #letter_logo_p').prop("checked", true);
		if(pvc_plexi_p=='1') $('#modFormWorkers #pvc_plexi_p').prop("checked", true);
		if(bache_p=='1') $('#modFormWorkers #bache_p').prop("checked", true);
		if(toile_p=='1') $('#modFormWorkers #toile_p').prop("checked", true);
		if(bache_xxl_p=='1') $('#modFormWorkers #bache_xxl_p').prop("checked", true);
		if(sign_nolight_p=='1') $('#modFormWorkers #sign_nolight_p').prop("checked", true);
		if(sign_light_p=='1') $('#modFormWorkers #sign_light_p').prop("checked", true);
		if(plv_p=='1') $('#modFormWorkers #plv_p').prop("checked", true);
		if(totem_p=='1') $('#modFormWorkers #totem_p').prop("checked", true);
		if(nacelle_p=='1') $('#modFormWorkers #nacelle_p').prop("checked", true);
		if(elec_p=='1') $('#modFormWorkers #elec_p').prop("checked", true);
		if(echaf_p=='1') $('#modFormWorkers #echaf_p').prop("checked", true);
		if(cord_p=='1') $('#modFormWorkers #cord_p').prop("checked", true);
		if(eq_caces_p=='1') $('#modFormWorkers #eq_caces_p').prop("checked", true);
		if(eq_secu_p=='1') $('#modFormWorkers #eq_secu_p').prop("checked", true);
		$('#modFormWorkers #eq_utilitaire_p').val(eq_utilitaire_p);
		if(eq_local_p=='1') $('#modFormWorkers #eq_local_p').prop("checked", true);
		if(eq_stock_p=='1') $('#modFormWorkers #eq_stock_p').prop("checked", true);
		if(eq_nacelle_p=='1') $('#modFormWorkers #eq_nacelle_p').prop("checked", true);
		if(eq_echaf_p=='1') $('#modFormWorkers #eq_echaf_p').prop("checked", true);
		$('#modFormWorkers #formation_p').val(formation_p);
		$('#modFormWorkers #zone_inter_p').val(zone_inter_p);
		$('#modFormWorkers #comments_p').val(comments_p.replace(/  /gi, "\r\n")); // Put back chariot's returns.
		$('#modFormWorkers #date_creation_p').val(date_creation_p);
		$('#modFormWorkers #date_mod_p').val(date_mod_p);
		$('#modWorkersModal').modal('show');
	},

	modWorker: function(myFormDiv)
	{
		$(myFormDiv+' #sender').attr("disabled", true);
		let query = $(myFormDiv).serialize();
		let req = "modWorker";
		// Serializing checkBoxes first for regular checkBoxes, second for Bootstrap's customs (assuming id=name actually works with both)...
		//let checkBoxes=$(myFormDiv+" input[type='checkbox']").map(function(){return this.name+"="+this.checked;}).get().join("&");
		//let checkBoxes=$(myFormDiv+" input[type='checkbox']").map(function(){return this.id+"="+this.checked;}).get().join("&");
		//query = query + "&" + checkBoxes + "&id=" + globals.id + "&pwd=" + globals.pwd + "&req=" + req;
		query = query + "&id=" + globals.id + "&pwd=" + globals.pwd + "&req=" + req;
		let returns = "";
		//$(myFormDiv+' #successfail').append('<div class="alert alert-success" role="alert"><b>Query : '+query+'</b></div>');
		$.post(globals.serverAddressWorker, query, function(data){
			if(data.ok=="ok") {
				returns = '<div class="alert alert-success" role="alert"><b>Cette fiche Médiaplicateur a bien été modifiée.</b></div>';
				App.getWorkersList();
				setTimeout(function(){
					$(myFormDiv).closest('.modal').modal('hide');
					App.clearFormFields(myFormDiv);
				}, 1600);
			}
			else
				returns = '<div class="alert alert-danger" role="alert"><b>Cette fiche Médiaplicateur n\'a pas été modifiée suite à un problème technique.</b></div>';
		}, "json").always(function(data){
			$(myFormDiv+' #sender').attr("disabled", false);
			$(myFormDiv+' #successfail').empty().append(returns);
		});
	},

	addWorker: function(myFormDiv)
	{
		$(myFormDiv+' #sender').attr("disabled", true);
		let query = $(myFormDiv).serialize();
		let req = "addWorker";
		// Serializing checkBoxes first for regular checkBoxes, second for Bootstrap's customs (assuming id=name)...
		//let checkBoxes=$(myFormDiv+" input[type='checkbox']").map(function(){return this.name+"="+this.checked;}).get().join("&");
		//let checkBoxes=$(myFormDiv+" input[type='checkbox']").map(function(){return this.id+"="+this.checked;}).get().join("&");
		//query = query + "&" + checkBoxes + "&id=" + globals.id + "&pwd=" + globals.pwd + "&req=" + req;
		query = query + "&id=" + globals.id + "&pwd=" + globals.pwd + "&req=" + req;
		let returns = "";
		//$(myFormDiv+' #successfail').append('<div class="alert alert-success" role="alert"><b>Query : '+query+'</b></div>');
		$.post(globals.serverAddressWorker, query, function(data){
			if(data.ok=="ok") {
				returns = '<div class="alert alert-success" role="alert"><b>Cette fiche Médiaplicateur a bien été ajoutée à votre personnel.</b></div>';
				App.getWorkersList();
				setTimeout(function(){
					$(myFormDiv).closest('.modal').modal('hide');
					App.clearFormFields(myFormDiv);
				}, 1600);
			}
			else
				returns = '<div class="alert alert-danger" role="alert"><b>Cette fiche Médiaplicateur n\'a pas été ajoutée à votre personnel, suite à un problème technique.</b></div>';
		}, "json").always(function(data){
			$(myFormDiv+' #sender').attr("disabled", false);
			$(myFormDiv+' #successfail').empty().append(returns);
		});
	},

	delWorker: function(myFormDiv, event)
	{
		event.preventDefault(); // prevents mod form submission !
		const confirmDeletion = confirm("Êtes-vous certain de vouloir supprimer cette fiche ?");
		if (confirmDeletion) {
			$(myFormDiv+' #deleter').attr("disabled", true);
			let delId = $(myFormDiv+' #auto_p').val();
			let req = "delWorker";
			let query = "auto_p=" + delId + "&id=" + globals.id + "&pwd=" + globals.pwd + "&req=" + req;
			let returns = "";
			//$(myFormDiv+' #successfail').append('<div class="alert alert-success" role="alert"><b>Query : '+query+'</b></div>');
			$.post(globals.serverAddressWorker, query, function(data){
				if(data.ok=="ok") {
					returns = '<div class="alert alert-success" role="alert"><b>Cette fiche Médiaplicateur a bien été supprimée.</b></div>';
					App.getWorkersList();
					setTimeout(function(){
						$(myFormDiv).closest('.modal').modal('hide');
						App.clearFormFields(myFormDiv);
					}, 1600);
				}
				else
					returns = '<div class="alert alert-danger" role="alert"><b>Cette fiche Médiaplicateur n\'a pas été supprimée suite à un problème technique.</b></div>';
			}, "json").always(function(data){
				$(myFormDiv+' #deleter').attr("disabled", false);
				$(myFormDiv+' #successfail').empty().append(returns);
			});
		}
	},

	getClientsList: function() {
		$.post(globals.serverAddressClient, {id: globals.id, type: globals.type, pwd: globals.pwd, req: 'getClientsList'}, function(data){
			$("#tabClientsCont").empty().append(data.snippet);
			//$.localStorage.setItem('workers', data.workers);
			//globals.workers = data.workers;
		}, "json").done(function() {
			//$('table').addClass('tablesorter');
			$('.tablesorterClients').tablesorter({
				theme: 'bootstrap', // dark or bootstrap are cool here.
				dateFormat : "ddmmyyyy", // set the default date format
				// hidden filter input/selects will resize the columns, so try to minimize the change
				widthFixed : true,
				// initialize zebra striping and filter widgets
				widgets: ["zebra", "filter", "cssStickyHeaders"],
				ignoreCase: false,
				widgetOptions : {
					// See options at : https://mottie.github.io/tablesorter/docs/example-widget-filter.html
					filter_childRows : false,
					filter_columnFilters : true,
					filter_cellFilter : '',
					filter_defaultFilter : {},
					filter_excludeFilter : {},
					filter_external : '',
					filter_filteredRow : 'filtered',
					filter_formatter : null,
					filter_functions : null,
					filter_hideEmpty : true,
					filter_hideFilters : false,
					filter_ignoreCase : true,
					filter_liveSearch : true,
					filter_startsWith : false,
					filter_onlyAvail : 'filter-onlyAvail',
					filter_placeholder : { search : 'Filtre', select : '' },
					filter_reset : 'button.reset',
					filter_saveFilters : false,
					filter_searchDelay : 300,
					filter_searchFiltered: true,
					filter_selectSource  : null,
					filter_serversideFiltering : false,
					filter_useParsedData : false,
					filter_defaultAttrib : 'data-value',
					filter_selectSourceSeparator : '|',
					// if false and a caption exist, it won't be included in the sticky header
					cssStickyHeaders_addCaption: true,
					// jQuery selector or object to attach sticky header to
					cssStickyHeaders_attachTo: null,
					// number or jquery selector targeting the position:fixed element
					cssStickyHeaders_offset: 70,
					// scroll table top into view after filtering
					cssStickyHeaders_filteredToTop : true,
					// extra css class name (string or array) added to the filter element (input or select)
					filter_cssFilter: []
				}
			}).tablesorterPager({
				container: $('#clientsPager'),
				size: 20
			});
		});
	},

	fillModClient: function(auto_cl, name_cl, mail_cl, pwd_cl, date_creation_cl, phone1_cl, phone2_cl, address_cl, zip_cl, city_cl, active_cl)
	{
		$('#modFormClients #auto_cl').val(auto_cl);
		$('#modFormClients #name_cl').val(name_cl);
		$('#modFormClients #mail_cl').val(mail_cl);
		$('#modFormClients #pwd_cl').val(pwd_cl);
		$('#modFormClients #date_creation_cl').val(date_creation_cl);
		$('#modFormClients #phone1_cl').val(phone1_cl);
		$('#modFormClients #phone2_cl').val(phone2_cl);
		$('#modFormClients #address_cl').val(address_cl);
		$('#modFormClients #zip_cl').val(zip_cl);
		$('#modFormClients #city_cl').val(city_cl);
		$('#modFormClients #active_cl').val(active_cl);
		$('#modClientsModal').modal('show');
	},

	modClient: function(myFormDiv)
	{
		$(myFormDiv+' #sender').attr("disabled", true);
		let query = $(myFormDiv).serialize();
		let req = "modClient";
		query = query + "&id=" + globals.id + "&pwd=" + globals.pwd + "&req=" + req;
		let returns = "";
		//$(myFormDiv+' #successfail').append('<div class="alert alert-success" role="alert"><b>Query : '+query+'</b></div>');
		$.post(globals.serverAddressClient, query, function(data){
			if(data.ok=="ok") {
				returns = '<div class="alert alert-success" role="alert"><b>Cette fiche client a bien été modifiée.</b></div>';
				App.getClientsList();
				setTimeout(function(){
					$(myFormDiv).closest('.modal').modal('hide');
					App.clearFormFields(myFormDiv);
				}, 1600);
			}
			else
				returns = '<div class="alert alert-danger" role="alert"><b>Cette fiche client n\'a pas été modifiée suite à un problème technique.</b></div>';
		}, "json").always(function(data){
			$(myFormDiv+' #sender').attr("disabled", false);
			$(myFormDiv+' #successfail').empty().append(returns);
		});
	},

	addClient: function(myFormDiv)
	{
		$(myFormDiv+' #sender').attr("disabled", true);
		let query = $(myFormDiv).serialize();
		let req = "addClient";
		query = query + "&id=" + globals.id + "&pwd=" + globals.pwd + "&req=" + req;
		let returns = "";
		//$(myFormDiv+' #successfail').append('<div class="alert alert-success" role="alert"><b>Query : '+query+'</b></div>');
		$.post(globals.serverAddressClient, query, function(data){
			if(data.ok=="ok") {
				returns = '<div class="alert alert-success" role="alert"><b>Cette fiche client a bien été ajoutée au carnet d\'adresse.</b></div>';
				App.getClientsList();
				setTimeout(function(){
					$(myFormDiv).closest('.modal').modal('hide');
					App.clearFormFields(myFormDiv);
				}, 1600);
			}
			else
				returns = '<div class="alert alert-danger" role="alert"><b>Cette fiche client n\'a pas été ajoutée au carnet d\'adresse, suite à un problème technique.</b></div>';
		}, "json").always(function(data){
			$(myFormDiv+' #sender').attr("disabled", false);
			$(myFormDiv+' #successfail').empty().append(returns);
		});
	},

	delClient: function(myFormDiv, event)
	{
		event.preventDefault(); // prevents mod form submission !
		const confirmDeletion = confirm("Êtes-vous certain de vouloir supprimer cette fiche ?");
		if (confirmDeletion) {
			$(myFormDiv+' #deleter').attr("disabled", true);
			let delId = $(myFormDiv+' #auto_cl').val();
			let req = "delClient";
			let query = "auto_cl=" + delId + "&id=" + globals.id + "&pwd=" + globals.pwd + "&req=" + req;
			let returns = "";
			//$(myFormDiv+' #successfail').append('<div class="alert alert-success" role="alert"><b>Query : '+query+'</b></div>');
			$.post(globals.serverAddressClient, query, function(data){
				if(data.ok=="ok") {
					returns = '<div class="alert alert-success" role="alert"><b>Cette fiche client a bien été supprimée.</b></div>';
					App.getClientsList();
					setTimeout(function(){
						$(myFormDiv).closest('.modal').modal('hide');
						App.clearFormFields(myFormDiv);
					}, 1600);
				}
				else
					returns = '<div class="alert alert-danger" role="alert"><b>Cette fiche client n\'a pas été supprimée suite à un problème technique.</b></div>';
			}, "json").always(function(data){
				$(myFormDiv+' #deleter').attr("disabled", false);
				$(myFormDiv+' #successfail').empty().append(returns);
			});
		}
	},

	getCampaignsList: function() {
		$.post(globals.serverAddressCampaign, {id: globals.id, type: globals.type, pwd: globals.pwd, req: 'getCampaignsList'}, function(data){
			$("#tabCampaignsCont").empty().append(data.snippet);
			//$.localStorage.setItem('workers', data.workers);
			//globals.workers = data.workers;
		}, "json").done(function() {
			//$('table').addClass('tablesorter');
			$('.tablesorterCampaigns').tablesorter({
				theme: 'bootstrap', // dark or bootstrap are cool here.
				dateFormat : "ddmmyyyy", // set the default date format
				// hidden filter input/selects will resize the columns, so try to minimize the change
				widthFixed : true,
				// initialize zebra striping and filter widgets
				//widgets: ["zebra", "filter"],
				widgets: ["filter", "cssStickyHeaders"],
				ignoreCase: false,
				widgetOptions : {
					// See options at : https://mottie.github.io/tablesorter/docs/example-widget-filter.html
					filter_childRows : false,
					filter_columnFilters : true,
					filter_cellFilter : '',
					// extra css class name (string or array) added to the filter element (input or select)
					filter_cssFilter: [
						'form-control',
						'form-control',
						'form-control',
						'form-control',
						'form-control',
						'form-control',
						'form-control',
						'form-control',
						'form-control',
						'form-control custom-select', // select needs custom class names :(
						'form-control custom-select', // select needs custom class names :(
						'form-control custom-select', // select needs custom class names :(
					],
					filter_defaultFilter : {},
					filter_excludeFilter : {},
					filter_external : '',
					filter_filteredRow : 'filtered',
					filter_formatter : null,
					filter_functions : null,
					filter_hideEmpty : true,
					filter_hideFilters : false,
					filter_ignoreCase : true,
					filter_liveSearch : true,
					filter_startsWith : false,
					filter_onlyAvail : 'filter-onlyAvail',
					filter_placeholder : { search : 'Filtre', select : '' },
					filter_reset : 'button.reset',
					filter_saveFilters : false,
					filter_searchDelay : 300,
					filter_searchFiltered: true,
					filter_selectSource  : null,
					filter_serversideFiltering : false,
					filter_useParsedData : false,
					filter_defaultAttrib : 'data-value',
					filter_selectSourceSeparator : '|',
					// if false and a caption exist, it won't be included in the sticky header
					cssStickyHeaders_addCaption: true,
					// jQuery selector or object to attach sticky header to
					cssStickyHeaders_attachTo: null,
					// number or jquery selector targeting the position:fixed element
					cssStickyHeaders_offset: 70,
					// scroll table top into view after filtering
					cssStickyHeaders_filteredToTop : true
				}
			}).tablesorterPager({
				container: $('#campaignsPager'),
				size: 20
			});
			const goTo = App.urlParam('goto', document.URL); // Where are we going
			const idCamp = App.urlParam('id_c', document.URL); // Then get the id
			if(goTo == 1) $("#modCampBtn_"+idCamp).trigger("click");
			else if(goTo == 2) {
				// Its a trick to avoid empty mod form without generating hidden button or something complicated
				setTimeout(function() {
					$("#pictCampBtn_"+idCamp).trigger("click");
					//App.getAndGoToCampaignsPicturesList(idCamp);
				}, 600);
			}
			else if(goTo == 3) {
				// Same trick to avoid empty mod form without generating hidden button or something complicated
				setTimeout(function() {
					$("#biCampBtn_"+idCamp).trigger("click");
					//App.getAndGoToCampaignsBiList(idCamp);
				}, 600);
			}
		});
	},

	getCampaignsWorkersList: function(auto_c) {
		$.post(globals.serverAddressCampaign, {auto_c: auto_c, id: globals.id, type: globals.type, pwd: globals.pwd, req: 'getCampaignsWorkersList'}, function(data){
			$("#tabCampaignsWorkersCont").empty().append(data.snippet);
		}, "json").done(function() {
			//$('table').addClass('tablesorter');
			$('.tablesorterCampaignsWorkers').tablesorter({
				theme: 'bootstrap', // dark or bootstrap are cool here.
				dateFormat : "ddmmyyyy", // set the default date format
				// hidden filter input/selects will resize the columns, so try to minimize the change
				widthFixed : true,
				// initialize zebra striping and filter widgets
				// widgets: ["zebra", "filter"],
				widgets: ["filter", "cssStickyHeaders"],
				ignoreCase: false,
				widgetOptions : {
					// See options at : https://mottie.github.io/tablesorter/docs/example-widget-filter.html
					filter_childRows : false,
					filter_columnFilters : true,
					filter_cellFilter : '',
					// extra css class name (string or array) added to the filter element (input or select)
					filter_cssFilter: [
						'form-control',
						'form-control',
						'form-control',
						'form-control',
						'form-control custom-select', // select needs custom class names :(
						'form-control custom-select', // select needs custom class names :(
						'form-control',
						'form-control',
						'form-control',
						'form-control',
						'form-control',
						'form-control',
						'form-control',
						'form-control',
					],
					filter_defaultFilter : {},
					filter_excludeFilter : {},
					filter_external : '',
					filter_filteredRow : 'filtered',
					filter_formatter : null,
					filter_functions : null,
					filter_hideEmpty : true,
					filter_hideFilters : false,
					filter_ignoreCase : true,
					filter_liveSearch : true,
					filter_startsWith : true,
					filter_onlyAvail : 'filter-onlyAvail',
					filter_placeholder : { search : 'Filtre', select : '' },
					filter_reset : 'button.reset',
					filter_saveFilters : false,
					filter_searchDelay : 300,
					filter_searchFiltered: true,
					filter_selectSource  : null,
					filter_serversideFiltering : false,
					filter_useParsedData : false,
					filter_defaultAttrib : 'data-value',
					filter_selectSourceSeparator : '|',
					// if false and a caption exist, it won't be included in the sticky header
					cssStickyHeaders_addCaption: true,
					// jQuery selector or object to attach sticky header to
					cssStickyHeaders_attachTo: null,
					// number or jquery selector targeting the position:fixed element
					cssStickyHeaders_offset: 70,
					// scroll table top into view after filtering
					cssStickyHeaders_filteredToTop : false
				}
			}).tablesorterPager({
				container: $('#campaignsWorkersPager'),
				size: 20
			});
		});
	},

	getAssignedCampaignsWorkersList: function(auto_c) {
		$.post(globals.serverAddressCampaign, {auto_c: auto_c, id: globals.id, type: globals.type, pwd: globals.pwd, req: 'getAssignedCampaignsWorkersList'}, function(data){
			$("#tabAssignedCampaignsWorkersCont").empty().append(data.snippet);
		}, "json").done(function() {
			//$('table').addClass('tablesorter');
			$('.tablesorterAssignedCampaignsWorkers').tablesorter({
				theme: 'bootstrap', // dark or bootstrap are cool here.
				dateFormat : "ddmmyyyy", // set the default date format
				// hidden filter input/selects will resize the columns, so try to minimize the change
				widthFixed : true,
				// initialize zebra striping and filter widgets
				// widgets: ["zebra", "filter"],
				widgets: ["filter", "cssStickyHeaders"],
				ignoreCase: false,
				widgetOptions : {
					// See options at : https://mottie.github.io/tablesorter/docs/example-widget-filter.html
					filter_childRows : false,
					filter_columnFilters : true,
					filter_cellFilter : '',
					// extra css class name (string or array) added to the filter element (input or select)
					filter_cssFilter: [
						'form-control',
						'form-control',
						'form-control',
						'form-control',
						'form-control custom-select', // select needs custom class names :(
						'form-control custom-select', // select needs custom class names :(
						'form-control',
						'form-control',
						'form-control',
						'form-control',
						'form-control',
						'form-control',
						'form-control',
						'form-control custom-select', // select needs custom class names :(
					],
					filter_defaultFilter : {},
					filter_excludeFilter : {},
					filter_external : '',
					filter_filteredRow : 'filtered',
					filter_formatter : null,
					filter_functions : null,
					filter_hideEmpty : true,
					filter_hideFilters : false,
					filter_ignoreCase : true,
					filter_liveSearch : true,
					filter_startsWith : true,
					filter_onlyAvail : 'filter-onlyAvail',
					filter_placeholder : { search : 'Filtre', select : '' },
					filter_reset : 'button.reset',
					filter_saveFilters : false,
					filter_searchDelay : 300,
					filter_searchFiltered: true,
					filter_selectSource  : null,
					filter_serversideFiltering : false,
					filter_useParsedData : false,
					filter_defaultAttrib : 'data-value',
					filter_selectSourceSeparator : '|',
					// if false and a caption exist, it won't be included in the sticky header
					cssStickyHeaders_addCaption: true,
					// jQuery selector or object to attach sticky header to
					cssStickyHeaders_attachTo: null,
					// number or jquery selector targeting the position:fixed element
					cssStickyHeaders_offset: 70,
					// scroll table top into view after filtering
					cssStickyHeaders_filteredToTop : false
				}
			}).tablesorterPager({
				container: $('#assignedCampaignsWorkersPager'),
				size: 20
			});
		});
	},

	searchWorkerFromCampaign: function(myFormDiv, myTabContDiv, myTabClass, myPagerDiv) {
		$(myFormDiv+' #sender').attr("disabled", true);
		let req = "searchWorkerFromCampaign";
		let query = $(myFormDiv).serialize();
		query = query + "&auto_c=" + globals.currentCampainId + "&tabclass=" + myTabClass + "&id=" + globals.id + "&type=" + globals.type + "&pwd=" + globals.pwd + "&req=" + req;
		$.post(globals.serverAddressCampaign, query, function(data){
			$(myTabContDiv).empty().append(data.snippet);
		}, "json").done(function() {
			//$('table').addClass('tablesorter');
			$(myTabClass).tablesorter({
				theme: 'bootstrap', // dark or bootstrap are cool here.
				dateFormat : "ddmmyyyy", // set the default date format
				// hidden filter input/selects will resize the columns, so try to minimize the change
				widthFixed : true,
				// initialize zebra striping and filter widgets
				widgets: ["zebra", "filter", "cssStickyHeaders"],
				ignoreCase: false,
				widgetOptions : {
					// See options at : https://mottie.github.io/tablesorter/docs/example-widget-filter.html
					filter_childRows : false,
					filter_columnFilters : true,
					filter_cellFilter : '',
					// extra css class name (string or array) added to the filter element (input or select)
					filter_cssFilter: [
						'form-control',
						'form-control',
						'form-control',
						'form-control',
						'form-control custom-select', // select needs custom class names :(
						'form-control custom-select', // select needs custom class names :(
						'form-control',
						'form-control',
						'form-control',
						'form-control',
						'form-control',
						'form-control',
						'form-control',
						'form-control custom-select', // select needs custom class names :(
					],
					filter_defaultFilter : {},
					filter_excludeFilter : {},
					filter_external : '',
					filter_filteredRow : 'filtered',
					filter_formatter : null,
					filter_functions : null,
					filter_hideEmpty : true,
					filter_hideFilters : false,
					filter_ignoreCase : true,
					filter_liveSearch : true,
					filter_startsWith : true,
					filter_onlyAvail : 'filter-onlyAvail',
					filter_placeholder : { search : 'Filtre', select : '' },
					filter_reset : 'button.reset',
					filter_saveFilters : false,
					filter_searchDelay : 300,
					filter_searchFiltered: true,
					filter_selectSource  : null,
					filter_serversideFiltering : false,
					filter_useParsedData : false,
					filter_defaultAttrib : 'data-value',
					filter_selectSourceSeparator : '|',
					// if false and a caption exist, it won't be included in the sticky header
					cssStickyHeaders_addCaption: true,
					// jQuery selector or object to attach sticky header to
					cssStickyHeaders_attachTo: null,
					// number or jquery selector targeting the position:fixed element
					cssStickyHeaders_offset: 70,
					// scroll table top into view after filtering
					cssStickyHeaders_filteredToTop : false
				}
			}).tablesorterPager({
				container: $(myPagerDiv),
				size: 20
			});
		}).always(function(){
			$(myFormDiv+' #sender').attr("disabled", false);
		});
	},

	filterAssigned: function(isHe, myTabClass)
	{
		$(myTabClass + ' tr.tablesorter-filter-row input[data-column=13]').val(isHe).trigger("change");
	},

	getCampaignsPicturesList: function(auto_c) {
		$.post(globals.serverAddressCampaign, {auto_c: auto_c, id: globals.id, type: globals.type, pwd: globals.pwd, req: 'getCampaignsPicturesList'}, function(data){
			$("#tabCampaignsPicturesCont").empty().append(data.snippet);
		}, "json").done(function() {
			//$('table').addClass('tablesorter');
			$('.tablesorterCampaignsPictures').tablesorter({
				theme: 'bootstrap', // dark or bootstrap are cool here.
				dateFormat : "ddmmyyyy", // set the default date format
				// hidden filter input/selects will resize the columns, so try to minimize the change
				widthFixed : true,
				// initialize zebra striping and filter widgets
				widgets: ["zebra", "filter", "cssStickyHeaders"],
				//widgets: ["filter"],
				ignoreCase: false,
				widgetOptions : {
					// See options at : https://mottie.github.io/tablesorter/docs/example-widget-filter.html
					filter_childRows : false,
					filter_columnFilters : true,
					filter_cellFilter : '',
					filter_cssFilter : '', // or []
					filter_defaultFilter : {},
					filter_excludeFilter : {},
					filter_external : '',
					filter_filteredRow : 'filtered',
					filter_formatter : null,
					filter_functions : null,
					filter_hideEmpty : true,
					filter_hideFilters : false,
					filter_ignoreCase : true,
					filter_liveSearch : true,
					filter_startsWith : true,
					filter_onlyAvail : 'filter-onlyAvail',
					filter_placeholder : { search : 'Filtre', select : '' },
					filter_reset : 'button.reset',
					filter_saveFilters : false,
					filter_searchDelay : 300,
					filter_searchFiltered: true,
					filter_selectSource  : null,
					filter_serversideFiltering : false,
					filter_useParsedData : false,
					filter_defaultAttrib : 'data-value',
					filter_selectSourceSeparator : '|',
					// if false and a caption exist, it won't be included in the sticky header
					cssStickyHeaders_addCaption: true,
					// jQuery selector or object to attach sticky header to
					cssStickyHeaders_attachTo: null,
					// number or jquery selector targeting the position:fixed element
					cssStickyHeaders_offset: 70,
					// scroll table top into view after filtering
					cssStickyHeaders_filteredToTop : false
				}
			}).tablesorterPager({
				container: $('#campaignsPicturesPager'),
				size: 20
			});
		});
	},

	getCampaignsBiList: function(auto_c) {
		$.post(globals.serverAddressCampaign, {auto_c: auto_c, id: globals.id, type: globals.type, pwd: globals.pwd, req: 'getCampaignsBiList'}, function(data){
			$("#tabCampaignsBiCont").empty().append(data.snippet);
		}, "json").done(function() {
			//$('table').addClass('tablesorter');
			$('.tablesorterCampaignsBi').tablesorter({
				theme: 'bootstrap', // dark or bootstrap are cool here.
				dateFormat : "ddmmyyyy", // set the default date format
				// hidden filter input/selects will resize the columns, so try to minimize the change
				widthFixed : true,
				// initialize zebra striping and filter widgets
				widgets: ["zebra", "filter", "cssStickyHeaders"],
				//widgets: ["filter"],
				ignoreCase: false,
				widgetOptions : {
					// See options at : https://mottie.github.io/tablesorter/docs/example-widget-filter.html
					filter_childRows : false,
					filter_columnFilters : true,
					filter_cellFilter : '',
					filter_cssFilter : '', // or []
					filter_defaultFilter : {},
					filter_excludeFilter : {},
					filter_external : '',
					filter_filteredRow : 'filtered',
					filter_formatter : null,
					filter_functions : null,
					filter_hideEmpty : true,
					filter_hideFilters : false,
					filter_ignoreCase : true,
					filter_liveSearch : true,
					filter_startsWith : true,
					filter_onlyAvail : 'filter-onlyAvail',
					filter_placeholder : { search : 'Filtre', select : '' },
					filter_reset : 'button.reset',
					filter_saveFilters : false,
					filter_searchDelay : 300,
					filter_searchFiltered: true,
					filter_selectSource  : null,
					filter_serversideFiltering : false,
					filter_useParsedData : false,
					filter_defaultAttrib : 'data-value',
					filter_selectSourceSeparator : '|',
					// if false and a caption exist, it won't be included in the sticky header
					cssStickyHeaders_addCaption: true,
					// jQuery selector or object to attach sticky header to
					cssStickyHeaders_attachTo: null,
					// number or jquery selector targeting the position:fixed element
					cssStickyHeaders_offset: 70,
					// scroll table top into view after filtering
					cssStickyHeaders_filteredToTop : false
				}
			}).tablesorterPager({
				container: $('#campaignsBiPager'),
				size: 20
			});
		});
	},

	getCampaignsWorkersPicturesList: function(auto_c, auto_p, thisBtn) {
		if(thisBtn) {
			if($(thisBtn).hasClass('btn-warning')) $(thisBtn).removeClass('btn-warning').addClass('btn-info');
		}
		$("#getCampaignsWorkersPicturesListModal").modal("show");
		$.post(globals.serverAddressCampaign, {auto_c: auto_c, auto_p: auto_p, id: globals.id, type: globals.type, pwd: globals.pwd, req: 'getCampaignsWorkersPicturesList'}, function(data){
			$("#tabCampaignsWorkersPicturesCont").empty().append(data.snippet);
		}, "json").done(function() {
			//$('table').addClass('tablesorter');
			$('.tablesorterCampaignsWorkersPictures').tablesorter({
				theme: 'bootstrap', // dark or bootstrap are cool here.
				dateFormat : "ddmmyyyy", // set the default date format
				// hidden filter input/selects will resize the columns, so try to minimize the change
				widthFixed : true,
				// initialize zebra striping and filter widgets
				//widgets: ["zebra", "filter"],
				widgets: ["filter", "cssStickyHeaders"],
				ignoreCase: false,
				widgetOptions : {
					// See options at : https://mottie.github.io/tablesorter/docs/example-widget-filter.html
					filter_childRows : false,
					filter_columnFilters : true,
					filter_cellFilter : '',
					// extra css class name (string or array) added to the filter element (input or select)
					filter_cssFilter: [
						'form-control',
						'form-control',
						'form-control',
						'form-control',
						'form-control',
						'form-control',
						'form-control',
						'form-control custom-select', // select needs custom class names :(
					],
					filter_defaultFilter : {},
					filter_excludeFilter : {},
					filter_external : '',
					filter_filteredRow : 'filtered',
					filter_formatter : null,
					filter_functions : null,
					filter_hideEmpty : true,
					filter_hideFilters : false,
					filter_ignoreCase : true,
					filter_liveSearch : true,
					filter_startsWith : true,
					filter_onlyAvail : 'filter-onlyAvail',
					filter_placeholder : { search : 'Filtre', select : '' },
					filter_reset : 'button.reset',
					filter_saveFilters : false,
					filter_searchDelay : 300,
					filter_searchFiltered: true,
					filter_selectSource  : null,
					filter_serversideFiltering : false,
					filter_useParsedData : false,
					filter_defaultAttrib : 'data-value',
					filter_selectSourceSeparator : '|',
					// if false and a caption exist, it won't be included in the sticky header
					cssStickyHeaders_addCaption: true,
					// jQuery selector or object to attach sticky header to
					cssStickyHeaders_attachTo: $("#getCampaignsWorkersPicturesListModal"),
					// number or jquery selector targeting the position:fixed element
					cssStickyHeaders_offset: 0,
					// scroll table top into view after filtering
					cssStickyHeaders_filteredToTop : false
				}
			}).tablesorterPager({
				container: $('#campaignsWorkersPicturesPager'),
				size: 20
			});
		});
	},

	getWorkersDocsList: function() {
		$.post(globals.serverAddressAdministratives, {id: globals.id, type: globals.type, pwd: globals.pwd, req: 'getWorkersDocsList'}, function(data){
			$("#tabWorkersDocsCont").empty().append(data.snippet);
			//$.localStorage.setItem('workers', data.workers);
			//globals.workers = data.workers;
		}, "json").done(function() {
			//$('table').addClass('tablesorter');
			$('.tablesorterDocsWorkers').tablesorter({
				theme: 'bootstrap', // dark or bootstrap are cool here.
				dateFormat : "ddmmyyyy", // set the default date format
				// hidden filter input/selects will resize the columns, so try to minimize the change
				widthFixed : true,
				// initialize zebra striping and filter widgets
				widgets: ["filter", "cssStickyHeaders"],
				ignoreCase: false,
				widgetOptions : {
					// See options at : https://mottie.github.io/tablesorter/docs/example-widget-filter.html
					filter_childRows : false,
					filter_columnFilters : true,
					filter_cellFilter : '',
					// extra css class name (string or array) added to the filter element (input or select)
					filter_cssFilter: [
						'form-control',
						'form-control',
						'form-control',
						'form-control',
						'form-control custom-select', // select needs custom class names :(
						'form-control custom-select', // select needs custom class names :(
						'form-control',
						'form-control',
						'form-control',
						'form-control',
						'form-control',
						'form-control custom-select', // select needs custom class names :(
					],
					filter_defaultFilter : {},
					filter_excludeFilter : {},
					filter_external : '',
					filter_filteredRow : 'filtered',
					filter_formatter : null,
					filter_functions : null,
					filter_hideEmpty : true,
					filter_hideFilters : false,
					filter_ignoreCase : true,
					filter_liveSearch : true,
					filter_startsWith : true,
					filter_onlyAvail : 'filter-onlyAvail',
					filter_placeholder : { search : 'Filtre', select : '' },
					filter_reset : 'button.reset',
					filter_saveFilters : false,
					filter_searchDelay : 300,
					filter_searchFiltered: true,
					filter_selectSource  : null,
					filter_serversideFiltering : false,
					filter_useParsedData : false,
					filter_defaultAttrib : 'data-value',
					filter_selectSourceSeparator : '|',
					// if false and a caption exist, it won't be included in the sticky header
					cssStickyHeaders_addCaption: true,
					// jQuery selector or object to attach sticky header to
					cssStickyHeaders_attachTo: null,
					// number or jquery selector targeting the position:fixed element
					cssStickyHeaders_offset: 70,
					// scroll table top into view after filtering
					cssStickyHeaders_filteredToTop : true
				}
			}).tablesorterPager({
				container: $('#workersDocsPager'),
				size: 20
			});
			const pop = App.urlParam('pop', document.URL); // What do we do
			const idWorker = App.urlParam('id_p', document.URL); // Then get the id
			if(pop == 1) {
				setTimeout(function() {
					$("#getWorkersDocumentsListBtn_"+idWorker).trigger("click");
				}, 600);
			}
		});
	},

	getWorkersDocumentsList: function(auto_p, thisBtn) {
		/*
		if(thisBtn) {
			if($(thisBtn).hasClass('btn-warning')) $(thisBtn).removeClass('btn-warning').addClass('btn-info');
		}
		*/
		$("#getWorkersDocumentsListModal").modal("show");
		$.post(globals.serverAddressAdministratives, {auto_p: auto_p, id: globals.id, type: globals.type, pwd: globals.pwd, req: 'getWorkersDocumentsList'}, function(data){
			$("#tabWorkersDocumentsCont").empty().append(data.snippet);
			$('#editDocumentsDatesFormCont').hide(); // Hide edit date mini-form
		}, "json").done(function() {
			//$('table').addClass('tablesorter');
			//var myWidgets =  (window.innerWidth > 1648) ? ["filter", "cssStickyHeaders"] : ["filter"];
			$('.tablesorterWorkersDocumentsList').tablesorter({
				theme: 'bootstrap', // dark or bootstrap are cool here.
				dateFormat : "ddmmyyyy", // set the default date format
				// hidden filter input/selects will resize the columns, so try to minimize the change
				widthFixed : true,
				// initialize zebra striping and filter widgets
				//widgets: ["zebra", "filter"],
				widgets: ["filter", "cssStickyHeaders"],
				ignoreCase: false,
				widgetOptions : {
					// See options at : https://mottie.github.io/tablesorter/docs/example-widget-filter.html
					filter_childRows : false,
					filter_columnFilters : true,
					filter_cellFilter : '',
					filter_cssFilter : '', // or []
					filter_defaultFilter : {},
					filter_excludeFilter : {},
					filter_external : '',
					filter_filteredRow : 'filtered',
					filter_formatter : null,
					filter_functions : null,
					filter_hideEmpty : true,
					filter_hideFilters : false,
					filter_ignoreCase : true,
					filter_liveSearch : true,
					filter_startsWith : true,
					filter_onlyAvail : 'filter-onlyAvail',
					filter_placeholder : { search : 'Filtre', select : '' },
					filter_reset : 'button.reset',
					filter_saveFilters : false,
					filter_searchDelay : 300,
					filter_searchFiltered: true,
					filter_selectSource  : null,
					filter_serversideFiltering : false,
					filter_useParsedData : false,
					filter_defaultAttrib : 'data-value',
					filter_selectSourceSeparator : '|',
					// if false and a caption exist, it won't be included in the sticky header
					cssStickyHeaders_addCaption: true,
					// jQuery selector or object to attach sticky header to
					cssStickyHeaders_attachTo: $("#getWorkersDocumentsListModal"),
					// number or jquery selector targeting the position:fixed element
					cssStickyHeaders_offset: 0,
					// scroll table top into view after filtering
					cssStickyHeaders_filteredToTop : false
				}
			}).tablesorterPager({
				container: $('#workersDocumentsPager'),
				size: 20
			});
		});
	},

	fillModCampaign: function(auto_c, pin_c, name_c, client_id_c, client_c, mail_c, cvsi_c, desc_c, how_many_actions, how_many_pictures, date_creation_c, date_start_c, date_end_c, user_c, over_c, type_c, action)
	{
		$('#modFormCampaigns #auto_c').val(auto_c);
		$('#modFormCampaigns #pin_c').val(pin_c);
		$('#modFormCampaigns #name_c').val(name_c);
		$('#modFormCampaigns #type_c').val(type_c);
		$('#modFormCampaigns #client_id_c').val(client_id_c);
		$('#modFormCampaigns #client_c').val(client_c);
		$('#modFormCampaigns #mail_c').val(mail_c);
		if(client_id_c=="18") {
			$("#modFormCampaigns #cvsi_c").val(cvsi_c);
			$("#modFormCampaigns #cvsi_box").show();
		}
		else  $("#modFormCampaigns #cvsi_box").hide();
		$('#modFormCampaigns #desc_c').val(desc_c.replace(/  /gi, "\r\n")); // Put back chariot's returns.
		$('#modFormCampaigns #how_many_actions').val(how_many_actions);
		$('#modFormCampaigns #how_many_pictures').val(how_many_pictures);
		$('#modFormCampaigns #date_creation_c').val(date_creation_c);
		$('#modFormCampaigns #date_start_c').val(date_start_c);
		$('#modFormCampaigns #date_end_c').val(date_end_c);
		$('#modFormCampaigns #user_c').val(user_c);
		$('#modFormCampaigns #user_c_old').val(user_c);
		$('#modFormCampaigns #over_c').val(over_c);
		$('#modFormCampaigns #uploadPlan').val('');
		$('#modFormCampaigns #uploadBi').val('');
		$(".custom-file-input").siblings(".custom-file-label").html('');
		$.post(globals.serverAddressCampaign, {auto_c: auto_c, id: globals.id, type: globals.type, pwd: globals.pwd, req: 'getCampaignsModUploads'}, function(data){ 
			if(data.ok=="ok") $('#modFormCampaigns #uploaded').empty().append(data.snippet);
			else $('#modFormCampaigns #uploaded').empty();
		}, "json");
		//$('#modFormCampaigns #active_c').val(active_c);
		//$('#modCampaignsModal').modal('show');
		App.getAssignedCampaignsWorkersList(auto_c);
		App.getCampaignsPicturesList(auto_c);
		// Only get BIs on demand => See #collapseFourModBtn click event
		//App.getCampaignsBiList(auto_c);
		globals.currentCampainId = auto_c;
		App.setCurrentCampaignName(name_c);
		switch (action) {
			case 'edit':
				$('#accordionModCampaign #collapseOneMod').collapse('show');
				$('#goTomodCampaigns').trigger('click');
				$('#collapseTwoModBtn').attr('disabled', false);
				$('#collapseThreeModBtn').attr('disabled', false);
				$('#collapseFourModBtn').attr('disabled', false);
				if(over_c=='1') $('#modFormCampaigns #deleter').attr('disabled', true);
				else  $('#modFormCampaigns #deleter').attr('disabled', false);
			break;
			case 'link':
				$('#accordionModCampaign #collapseTwoMod').collapse('show');
				$('#goTomodCampaigns').trigger('click');
				$('#collapseTwoModBtn').attr('disabled', false);
				$('#collapseThreeModBtn').attr('disabled', false);
				$('#collapseFourModBtn').attr('disabled', false);
			break;
			case 'camera':
				$('#accordionModCampaign #collapseThreeMod').collapse('show');
				$('#goTomodCampaigns').trigger('click');
				$('#collapseTwoModBtn').attr('disabled', false);
				$('#collapseThreeModBtn').attr('disabled', false);
				$('#collapseFourModBtn').attr('disabled', false);
			break;
			case 'bi':
				App.getCampaignsBiList(auto_c);
				$('#accordionModCampaign #collapseFourMod').collapse('show');
				$('#goTomodCampaigns').trigger('click');
				$('#collapseTwoModBtn').attr('disabled', false);
				$('#collapseThreeModBtn').attr('disabled', false);
				$('#collapseFourModBtn').attr('disabled', false);
			break;
			default:
				$('#accordionModCampaign #collapseOneMod').collapse('show');
				$('#goTomodCampaigns').trigger('click');
				$('#collapseTwoModBtn').attr('disabled', false);
				$('#collapseThreeModBtn').attr('disabled', false);
				$('#collapseFourModBtn').attr('disabled', false);
		}
	},

	delAttachedFile: function(auto_at, thisBtn, event)
	{
		event.preventDefault(); // prevents mod form submission !
		const confirmDeletion = confirm("Êtes-vous certain de vouloir supprimer ce document ?");
		if (confirmDeletion) {
			$(thisBtn).attr("disabled", true);
			$.post(globals.serverAddressCampaign, {auto_at: auto_at, id: globals.id, type: globals.type, pwd: globals.pwd, req: 'delAttachedFile'}, function(data){
				if(data.ok=="ok") {
					$(thisBtn).closest('li').remove();
					//alert('Document supprimé.');
				}
				else alert('Le document n\'a pas été supprimé.');
			}, "json").always(function(data){
				$(thisBtn).attr("disabled", false);
			});
		}
	},

	getAndGoToAssignedCampaignsWorkersList: function(auto_c)
	{
		$('#accordionModCampaign #collapseTwoMod').collapse('show');
		$('#goTomodCampaigns').trigger('click');
		//App.getAssignedCampaignsWorkersList(auto_c);
		//App.getCampaignsPicturesList(auto_c);
		$('#collapseTwoModBtn').attr('disabled', false);
		$('#collapseThreeModBtn').attr('disabled', false);
		$('#collapseFourModBtn').attr('disabled', false);
	},

	getAndGoToCampaignsPicturesList: function(auto_c)
	{
		$('#accordionModCampaign #collapseThreeMod').collapse('show');
		$('#goTomodCampaigns').trigger('click');
		//App.getCampaignsPicturesList(auto_c);
		//App.getAssignedCampaignsWorkersList(auto_c);
		$('#collapseTwoModBtn').attr('disabled', false);
		$('#collapseThreeModBtn').attr('disabled', false);
		$('#collapseFourModBtn').attr('disabled', false);
	},

	getAndGoToCampaignsBiList: function(auto_c)
	{
		$('#accordionModCampaign #collapseFourMod').collapse('show');
		$('#goTomodCampaigns').trigger('click');
		//App.getCampaignsPicturesList(auto_c);
		//App.getAssignedCampaignsWorkersList(auto_c);
		$('#collapseTwoModBtn').attr('disabled', false);
		$('#collapseThreeModBtn').attr('disabled', false);
		$('#collapseFourModBtn').attr('disabled', false);
	},

	generateUserSelect: function(myFormDiv)
	{
		let req = "generateUserSelect";
		const query = "&id=" + globals.id + "&type=" + globals.type + "&pwd=" + globals.pwd + "&req=" + req;
		$.post(globals.serverAddressCampaign, query, function(data){
			$(myFormDiv + " #user_c_cont").empty().append(data);
		});
	},

	generateClientSelect: function(myFormDiv)
	{
		let req = "generateClientSelect";
		const query = "&id=" + globals.id + "&type=" + globals.type + "&pwd=" + globals.pwd + "&req=" + req;
		$.post(globals.serverAddressCampaign, query, function(data){
			$(myFormDiv + " #client_id_c_cont").empty().append(data);
			const goTo = App.urlParam('goto', document.URL); // Where are we going
			const idClient = App.urlParam('id_cl', document.URL); // Then get the id
			//const nameClient = App.urlParam('client_id_c', document.URL);
			//const mailClient = App.urlParam('client_id_c', document.URL);
			$(myFormDiv + " #client_id_c").val(idClient);
			if(idClient>0) App.fillClientCampaign(idClient);
			if(goTo == 0) {
				$('#accordionAddCampaign #collapseOneAdd').collapse('show');
				$('#goToAddCampaigns').trigger('click');
			}
		});
	},

	fillClientCampaign: function(client_id_c)
	{
		if(!client_id_c) client_id_c = $('#addFormCampaigns #client_id_c').val();
		let req = "fillClientCampaign";
		const query = "&client_id_c=" + client_id_c + "&id=" + globals.id + "&type=" + globals.type + "&pwd=" + globals.pwd + "&req=" + req;
		$.post(globals.serverAddressCampaign, query, function(data){
			$('#addFormCampaigns #client_c').val(data.name_cl);
			$('#addFormCampaigns #mail_c').val(data.mail_cl);
		}, "json");
		// CVSI Particular Case
		if(client_id_c=="18") $("#addFormCampaigns #cvsi_box").show();
		else  $("#addFormCampaigns #cvsi_box").hide();
	},

	modCampaign: function(myFormDiv)
	{
		$(myFormDiv+' #sender').attr("disabled", true);
		//var query = $(myFormDiv).serialize();
		let req = "modCampaign";
		let returns = "";
		var request = new FormData($(myFormDiv)[0]);
		//request.append('file', $(myFormDiv+' input[type=file]')[0].files[0]);
		request.append("req", req);
		request.append("id", globals.id);
		request.append("type", globals.type);
		request.append("pwd", globals.pwd);
		request.append("calendarId", globals.calendar);
		// if date_creation_c select is disabled then it is not serialized
		request.append("date_creation_c", $(myFormDiv+' #date_creation_c').val());
		// if user_c select is disabled then it is not serialized, so when user is not admin it won't send the info unless we manually add it to the query !
		if($(myFormDiv+' #user_c').is(':disabled')) request.append("user_c", $(myFormDiv+' #user_c').val());
		// Handling user_c change concerning the Agendas => In case there's a change we need old user Agenda...
		request.append("user_c_old", $(myFormDiv+' #user_c_old').val());
		$(myFormDiv+' #successfail').append('<div class="alert alert-info text-center" role="alert"><b>Veuillez patienter pendant le traitement de la demande...</b><br><img src="assets/img/ajax-loader.gif" width="220px"/></div>');
		$.ajax({
			url: globals.serverAddressCampaign,
			type: 'POST',
			data: request,
			dataType: "json",
			cache: false,
			contentType: false,
			processData: false
		}).done(function(data){
			if(data.ok=="ok") {
				returns = '<div class="alert alert-success" role="alert"><b>Cette campagne a bien été mise à jour.</b></div>';
				App.getCampaignsList();
				globals.currentCampainId = data.auto_c;
				App.setCurrentCampaignName(data.name_c);
				setTimeout(function(){
					//App.clearFormFields(myFormDiv);
					//$(myFormDiv).closest('.collapse').collapse('hide');
					//$('#accordionModCampaign #collapseTwo').collapse('show');
					$('#accordionModCampaign #collapseTwoModBtn').trigger('click');
				}, 1600);
				$(myFormDiv+' #uploadPlan').val('');
				$(myFormDiv+' #uploadBi').val('');
				$(".custom-file-input").siblings(".custom-file-label").html('');
			}
			else
				returns = '<div class="alert alert-danger" role="alert"><b>Cette campagne n\'a pas mise à jour suite à un problème technique.</b></div>';
		}).always(function(data){
			$(myFormDiv+' #sender').attr("disabled", false);
			$(myFormDiv+' #successfail').empty().append(returns);
		});
		/*
		$.post(globals.serverAddressCampaign, query, function(data){
			if(data.ok=="ok") {
				returns = '<div class="alert alert-success" role="alert"><b>Cette campagne a bien été mise à jour.</b></div>';
				App.getCampaignsList();
				globals.currentCampainId = data.auto_c;
				setTimeout(function(){
					$('#accordionModCampaign #collapseTwoModBtn').trigger('click');
				}, 1600);
			}
			else
				returns = '<div class="alert alert-danger" role="alert"><b>Cette campagne n\'a pas mise à jour suite à un problème technique.</b></div>';
		}, "json").always(function(data){
			$(myFormDiv+' #sender').attr("disabled", false);
			$(myFormDiv+' #successfail').empty().append(returns);
		});
		*/
	},

	addCampaign: function(myFormDiv)
	{
		$(myFormDiv+' #sender').attr("disabled", true);
		let query = $(myFormDiv).serialize();
		let req = "addCampaign";
		query = query + "&id=" + globals.id + "&type=" + globals.type + "&pwd=" + globals.pwd + "&req=" + req;
		let returns = "";
		var request = new FormData($(myFormDiv)[0]);
		request.append("req", req);
		request.append("id", globals.id);
		request.append("pwd", globals.pwd);
		request.append("calendarId", globals.calendar);
		$(myFormDiv+' #successfail').append('<div class="alert alert-info text-center" role="alert"><b>Veuillez patienter pendant le traitement de la demande...</b><br><img src="assets/img/ajax-loader.gif" width="220px"/></div>');
		$.ajax({
			url: globals.serverAddressCampaign,
			type: 'POST',
			data: request,
			dataType: "json",
			cache: false,
			contentType: false,
			processData: false
		}).done(function(data){
			if(data.ok=="ok") {
				returns = '<div class="alert alert-success" role="alert"><b>Cette campagne a bien été ajoutée à votre liste.</b></div>';
				globals.currentCampainId = data.auto_c;
				App.setCurrentCampaignName(data.name_c);
				App.getCampaignsWorkersList(data.auto_c);
				App.getCampaignsList();
				$('#accordionAddCampaign #collapseTwoAddBtn').attr('disabled', false);
				setTimeout(function(){
					//$(myFormDiv).closest('.collapse').collapse('hide');
					//$('#accordionAddCampaign #collapseTwoAdd').collapse('show');
					$('#accordionAddCampaign #collapseTwoAddBtn').trigger('click');
					App.clearFormFields(myFormDiv);
				}, 1600);
			}
			else
				returns = '<div class="alert alert-danger" role="alert"><b>Cette campagne n\'a pas été ajoutée à votre liste, suite à un problème technique.</b></div>';
		}).always(function(data){
			$(myFormDiv+' #sender').attr("disabled", false);
			$(myFormDiv+' #successfail').empty().append(returns);
		});
		/*
		$.post(globals.serverAddressCampaign, query, function(data){
			if(data.ok=="ok") {
				returns = '<div class="alert alert-success" role="alert"><b>Cette campagne a bien été ajoutée à votre liste.</b></div>';
				globals.currentCampainId = data.auto_c;
				App.getCampaignsWorkersList(data.auto_c);
				App.getCampaignsList();
				setTimeout(function(){
					App.clearFormFields(myFormDiv);
					$('#accordionAddCampaign #collapseTwoAddBtn').trigger('click');
					$('#collapseTwoAddBtn').attr('disabled', false);
				}, 1600);
			}
			else
				returns = '<div class="alert alert-danger" role="alert"><b>Cette campagne n\'a pas été ajoutée à votre liste, suite à un problème technique.</b></div>';
		}, "json").always(function(data){
			$(myFormDiv+' #sender').attr("disabled", false);
			$(myFormDiv+' #successfail').empty().append(returns);
		});
		*/
	},

	delCampaign: function(myFormDiv, event)
	{
		event.preventDefault(); // prevents mod form submission !
		const confirmDeletion = confirm("Êtes-vous certain de vouloir supprimer cette campagne ?");
		if (confirmDeletion) {
			$(myFormDiv+' #deleter').attr("disabled", true);
			// let req = "delCampaign";
			var query = $(myFormDiv).serialize();
			if($(myFormDiv+' #user_c').is(':disabled')) query = query + "&user_c=" + $(myFormDiv+' #user_c').val() + "&id=" + globals.id + "&pwd=" + globals.pwd + "&calendarId=" + globals.calendar + "&req=delCampaign";
			else query = query + "&id=" + globals.id + "&type=" + globals.type + "&pwd=" + globals.pwd + "&calendarId=" + globals.calendar + "&req=delCampaign";
			let returns = "";
			//$(myFormDiv+' #successfail').append('<div class="alert alert-success" role="alert"><b>Query : '+query+'</b></div>');
			$.post(globals.serverAddressCampaign, query, function(data){
				if(data.ok=="ok") {
					returns = '<div class="alert alert-success" role="alert"><b>Cette campagne a bien été supprimée.</b></div>';
					App.getCampaignsList();
					setTimeout(function(){
						App.clearFormFields(myFormDiv);
						$(myFormDiv).closest('.collapse').collapse('hide');
						$('#collapseTwoModBtn').attr('disabled', true);
						$('#collapseThreeModBtn').attr('disabled', true);
					}, 1600);
				}
				else
					returns = '<div class="alert alert-danger" role="alert"><b>Cette campagne n\'a pas été supprimée suite à un problème technique.</b></div>';
			}, "json").always(function(data){
				$(myFormDiv+' #deleter').attr("disabled", false);
				$(myFormDiv+' #successfail').empty().append(returns);
			});
		}
	},

	duplicateCampaign: function(myFormDiv, event)
	{
		event.preventDefault(); // prevents mod form submission !
		const confirmDuplication = confirm("Êtes-vous certain de vouloir dupliquer cette campagne ?\r\nSi oui pensez à renseigner les nouvelles informations dans ce formulaire avant de dupliquer.");
		if (confirmDuplication) {
			$(myFormDiv+' #duplicator').attr("disabled", true);
			var query = $(myFormDiv).serialize();
			// let req = "duplicateCampaign";
			if($(myFormDiv+' #user_c').is(':disabled')) query = query + "&user_c=" + $(myFormDiv+' #user_c').val() + "&id=" + globals.id + "&pwd=" + globals.pwd + "&calendarId=" + globals.calendar + "&req=duplicateCampaign";
			else query = query + "&id=" + globals.id + "&type=" + globals.type + "&pwd=" + globals.pwd + "&calendarId=" + globals.calendar + "&req=duplicateCampaign";
			let returns = "";
			$.post(globals.serverAddressCampaign, query, function(data){
				if(data.ok=="ok") {
					returns = '<div class="alert alert-success" role="alert"><b>Cette campagne a bien été duppliquée, ici vous modifiez la campagne créée.</b></div>';
					App.getCampaignsList();
					$(myFormDiv+' #auto_c').val(data.auto_c);
					$(myFormDiv+' #pin_c').val(data.pin_c);
					$(myFormDiv+' #over_c').val(0); // Set this campaign state to "En cours"
					globals.currentCampainId = data.auto_c;
					App.setCurrentCampaignName(data.name_c);
					App.getAssignedCampaignsWorkersList(data.auto_c);
					App.getCampaignsPicturesList(data.auto_c);
					setTimeout(function(){
						App.clearFormFields(myFormDiv);
					}, 2600);
				}
				else
					returns = '<div class="alert alert-danger" role="alert"><b>Cette campagne n\'a pas été duppliquée suite à un problème technique.</b></div>';
			}, "json").always(function(data){
				$(myFormDiv+' #duplicator').attr("disabled", false);
				$(myFormDiv+' #successfail').empty().append(returns);
			});
		}
	},

	cvsiUpload: function(myFormDiv, event)
	{
		event.preventDefault(); // prevents mod form submission !
		const confirmUpload = confirm("Êtes-vous certain de vouloir télé-verser les photos de cette campagne ?");
		if (confirmUpload) {
			$(myFormDiv+' #cvsiUploadBtn').attr("disabled", true);
			let returns = "";
			if($(myFormDiv+' #cvsi_c').val()!="") {
				$.post(globals.serverAddressCampaign, { cvsi_c: $(myFormDiv+' #cvsi_c').val(), auto_c: $(myFormDiv+' #auto_c').val(), id: globals.id, type: globals.type, pwd: globals.pwd, req: "cvsiUpload"}, function(data){
					if(data.ok=="ok") {
						alert('Le transfert des photos vers CVSI a réussi.');
					}
					else
						alert('Le transfert des photos vers CVSI a échoué suite à un problème technique.'+data.response);
				}, "json").always(function(data){
					$(myFormDiv+' #cvsiUploadBtn').attr("disabled", false);
				}).fail(function(jqXHR, textStatus, errorThrown){
					alert('Le transfert des photos vers CVSI a échoué suite à un problème technique.\n'+textStatus+'\n'+errorThrown);
				});
			}
			else {
				alert('Le champs "N&deg; de Devis" est vide !');
				$(myFormDiv+' #cvsiUploadBtn').attr("disabled", false);
			}
		}
	},

	assignWorker: function(auto_c, auto_p, name_p, login_p, contact_p, phone_p, pwd_p, thisBtn)
	{
		$(thisBtn).attr("disabled", true);
		var thisCase = $(thisBtn).closest('td');
		let req = "assignWorker";
		let query = "auto_c=" + auto_c + "&auto_p=" + auto_p + "&name_p=" + name_p + "&login_p=" + login_p + "&contact_p=" + contact_p + "&phone_p=" + phone_p + "&pwd_p=" + pwd_p + "&id=" + globals.id + "&type=" + globals.type + "&pwd=" + globals.pwd + "&req=" + req;
		//$(myFormDiv+' #successfail').append('<div class="alert alert-success" role="alert"><b>Query : '+query+'</b></div>');
		$.post(globals.serverAddressCampaign, query, function(data){
			if(data.ok=="ok") {
				thisCase.closest('td').empty().append('<button class="btn btn-danger btn-block" onClick="App.deAssignWorker(\''+auto_c+'\', \''+auto_p+'\', \''+name_p+'\', \''+login_p+'\', \''+contact_p+'\', \''+phone_p+'\', \''+pwd_p+'\', this)"><i class="fa fa-unlink"></i><b> Désassigner</b></button><button class="btn btn-outline-warning btn-block" onClick="App.fillAddPictureFor(\''+auto_c+'\', \''+auto_p+'\')"><i class="fa fa-camera"></i><b> Déposer photos</b></button>');
				thisCase.prev('td').empty().append('Oui');
			}
			else {
				alert('Ce Médiaplicateur n\'a pas été assigné à cette campagne, suite à un problème technique.');
				$(thisBtn).attr("disabled", false);
			}
		}, "json").fail(function(jqXHR, textStatus, errorThrown){
			alert('Ce Médiaplicateur n\'a pas été assigné à cette campagne, suite à un problème technique.\n'+textStatus+'\n'+errorThrown);
			$(thisBtn).attr("disabled", false);
		});
	},

	deAssignWorker: function(auto_c, auto_p, name_p, login_p, contact_p, phone_p, pwd_p, thisBtn)
	{
		$(thisBtn).attr("disabled", true);
		var thisCase = $(thisBtn).closest('td');
		let req = "deAssignWorker";
		let query = "auto_c=" + auto_c + "&auto_p=" + auto_p + "&name_p=" + name_p + "&login_p=" + login_p + "&contact_p=" + contact_p + "&phone_p=" + phone_p + "&pwd_p=" + pwd_p + "&id=" + globals.id + "&type=" + globals.type + "&pwd=" + globals.pwd + "&req=" + req;
		//$(myFormDiv+' #successfail').append('<div class="alert alert-success" role="alert"><b>Query : '+query+'</b></div>');
		$.post(globals.serverAddressCampaign, query, function(data){
			if(data.ok=="ok") {
				thisCase.empty().append('<button class="btn btn-warning btn-block" onClick="App.assignWorker(\''+auto_c+'\', \''+auto_p+'\', \''+name_p+'\', \''+login_p+'\', \''+contact_p+'\', \''+phone_p+'\', \''+pwd_p+'\', this)"><i class="fa fa-link"></i><b> Assigner</b></button>');
				thisCase.prev('td').empty().append('Non');
			}
			else {
				alert('Ce Médiaplicateur n\'a pas été désassigné à cette campagne, suite à un problème technique.');
				$(thisBtn).attr("disabled", false);
			}
		}, "json").fail(function(jqXHR, textStatus, errorThrown){
			alert('Ce Médiaplicateur n\'a pas été désassigné à cette campagne, suite à un problème technique.\n'+textStatus+'\n'+errorThrown);
			$(thisBtn).attr("disabled", false);
		});
	},

	fillAssignWorkerFromWorker: function(auto_p, name_p, login_p, contact_p, phone_p, pwd_p)
	{
		App.generateCampaignSelect('#assignWorkerFromWorker');
		$('#assignWorkerFromWorker #auto_p').val(auto_p);
		$('#assignWorkerFromWorker #name_p').val(name_p);
		$('#assignWorkerFromWorker #login_p').val(login_p);
		$('#assignWorkerFromWorker #contact_p').val(contact_p);
		$('#assignWorkerFromWorker #phone_p').val(phone_p);
		$('#assignWorkerFromWorker #pwd_p').val(pwd_p);
		$('#assignWorkerFromWorkerModal').modal('show');
	},

	showAssignCampWorker: function(auto_p, name_p, thisBtn)
	{
		$(thisBtn).attr("disabled", true);
		$('#showAssignCampWorkerModal #workersNameTag').empty().append(name_p);
		$('#showAssignCampWorkerModal').modal('show');
		$.post(globals.serverAddressWorker, {auto_p: auto_p, id: globals.id, type: globals.type, pwd: globals.pwd, req: 'showAssignCampWorker'}, function(data){ 
			if(data.ok=="ok") {
				$("#tabAssignCampWorkerCont").empty().append(data.snippet);
			}
			else {
				$("#tabAssignCampWorkerCont").empty().append(data.snippet);
				alert('Ce Médiaplicateur n\'a pas d\'assignation actuellement.');
			}
		}, "json").done(function() {
			//$('table').addClass('tablesorter');
			$('.tablesorterAssignCampWorker').tablesorter({
				theme: 'bootstrap', // dark or bootstrap are cool here.
				dateFormat : "ddmmyyyy", // set the default date format
				// hidden filter input/selects will resize the columns, so try to minimize the change
				widthFixed : true,
				// initialize zebra striping and filter widgets
				//widgets: ["zebra", "filter"],
				widgets: ["filter", "cssStickyHeaders"],
				ignoreCase: false,
				widgetOptions : {
					// See options at : https://mottie.github.io/tablesorter/docs/example-widget-filter.html
					filter_childRows : false,
					filter_columnFilters : true,
					filter_cellFilter : '',
					filter_cssFilter : '', // or []
					filter_defaultFilter : {},
					filter_excludeFilter : {},
					filter_external : '',
					filter_filteredRow : 'filtered',
					filter_formatter : null,
					filter_functions : null,
					filter_hideEmpty : true,
					filter_hideFilters : false,
					filter_ignoreCase : true,
					filter_liveSearch : true,
					filter_startsWith : true,
					filter_onlyAvail : 'filter-onlyAvail',
					filter_placeholder : { search : 'Filtre', select : '' },
					filter_reset : 'button.reset',
					filter_saveFilters : false,
					filter_searchDelay : 300,
					filter_searchFiltered: true,
					filter_selectSource  : null,
					filter_serversideFiltering : false,
					filter_useParsedData : false,
					filter_defaultAttrib : 'data-value',
					filter_selectSourceSeparator : '|',
					// if false and a caption exist, it won't be included in the sticky header
					cssStickyHeaders_addCaption: true,
					// jQuery selector or object to attach sticky header to
					cssStickyHeaders_attachTo: $("#showAssignCampWorkerModal"),
					// number or jquery selector targeting the position:fixed element
					cssStickyHeaders_offset: 0,
					// scroll table top into view after filtering
					cssStickyHeaders_filteredToTop : false
				}
			}).tablesorterPager({
				container: $('#showAssignCampWorkerPager'),
				size: 20
			});
		}).fail(function(jqXHR, textStatus, errorThrown){
			alert('Il y a un problème technique.\n'+textStatus+'\n'+errorThrown);
		}).always(function(data){
			$(thisBtn).attr("disabled", false);
		});
	},

	generateCampaignSelect: function(myFormDiv)
	{
		let req = "generateCampaignSelect";
		const query = "&id=" + globals.id + "&type=" + globals.type + "&pwd=" + globals.pwd + "&req=" + req;
		$.post(globals.serverAddressWorker, query, function(data){
			$(myFormDiv + " #auto_c_cont").empty().append(data);
		});
	},

	assignWorkerFromWorker: function(myFormDiv)
	{
		$(myFormDiv+' #sender').attr("disabled", true);
		let query = $(myFormDiv).serialize();
		let req = "assignWorker";
		query = query + "&id=" + globals.id + "&type=" + globals.type + "&pwd=" + globals.pwd + "&req=" + req;
		let returns = "";
		//$(myFormDiv+' #successfail').append('<div class="alert alert-success" role="alert"><b>Query : '+query+'</b></div>');
		$.post(globals.serverAddressWorker, query, function(data){
			if(data.ok=="ok") {
				returns = '<div class="alert alert-success" role="alert"><b>Ce Médiapplicateur bien été assigné à la campagne.</b></div>';
				App.getClientsList();
				setTimeout(function(){
					$(myFormDiv).closest('.modal').modal('hide');
					App.clearFormFields(myFormDiv);
				}, 1600);
			}
			else
				returns = '<div class="alert alert-danger" role="alert"><b>Ce Médiapplicateur n\'a pas été assigné à la campagne suite à un problème technique.</b></div>';
		}, "json").always(function(data){
			$(myFormDiv+' #sender').attr("disabled", false);
			$(myFormDiv+' #successfail').empty().append(returns);
		});
	},

	fillAddPictureFor: function(auto_c, auto_p)
	{
		$('#addPictureForForm #auto_c').val(auto_c);
		$('#addPictureForForm #auto_p').val(auto_p);
		$('#addPictureForModal').modal('show');
	},

	addPictureFor: function(myFormDiv)
	{
		$(myFormDiv+' #sender').attr("disabled", true).html('<i class="fa fa-spinner"></i>&nbsp;Veuillez patienter');
		//let query = $(myFormDiv).serialize();
		let req = "addPictureFor";
		//query = query + "&id=" + globals.id + "&pwd=" + globals.pwd + "&req=" + req;
		let pictureLat = 0;
		let pictureLng = 0;
		const geoAddress = $(myFormDiv+' #street').val() + ", " + $(myFormDiv+' #zip').val() + ' ' + $(myFormDiv+' #city').val();
		$.get('https://nominatim.openstreetmap.org/search/', {q: geoAddress, addressdetails: 1, format: "geojson"}, function(data) {
		}, "json").done(function(data) {
			if(data.features[0]) {
				// Geocoding is OK...
				pictureLat = data.features[0].geometry.coordinates[1];
				pictureLng = data.features[0].geometry.coordinates[0];
			}
		}).always(function() {
			let returns = "";
			var request = new FormData();
			request.append('file', $(myFormDiv+' input[type=file]')[0].files[0]);
			request.append("req", req);
			request.append("id", globals.id);
			request.append("pwd", globals.pwd);
			request.append("street", $(myFormDiv+' #street').val());
			request.append("zip", $(myFormDiv+' #zip').val());
			request.append("city", $(myFormDiv+' #city').val());
			request.append("lat", pictureLat);
			request.append("lng", pictureLng);
			request.append("auto_c", $(myFormDiv+' #auto_c').val());
			request.append("auto_p", $(myFormDiv+' #auto_p').val());
			$(myFormDiv+' #successfail').append('<div class="alert alert-info text-center" role="alert"><b>Veuillez patienter pendant le traitement de la demande...</b><br><img src="assets/img/ajax-loader.gif" width="220px"/></div>');
			$.ajax({
				url: globals.serverAddressCampaign,
				type: 'POST',
				data: request,
				/*
				success:function(data){
					$('#output').html(data);
				},
				*/
				dataType: "json",
				cache: false,
				contentType: false,
				processData: false
			}).done(function(data){
				if(data.ok=="ok") {
					returns = '<div class="alert alert-success" role="alert"><b>Cette image a bien été ajoutée à la campagne pour ce Médiaplicateur.</b></div>';
					/*
					setTimeout(function(){
						App.clearFormFields(myFormDiv);
					}, 1000);
					*/
				}
				else
					returns = '<div class="alert alert-danger" role="alert"><b>Cette image n\'a pas été ajoutée à la campagne pour ce Médiaplicateur, suite à un problème technique.</b></div>';
			}).always(function(){
				$(myFormDiv+' #sender').attr("disabled", false).html('<i class="fa fa-check-circle"></i> Enregistrer</button>');
				$(myFormDiv+' #successfail').empty().append(returns);
				setTimeout(function(){
					$(myFormDiv+' #successfail').empty();
				}, 2000);
			});
		});
	},

	fillEditPicturesAddress: function(auto_docs)
	{
		$('#editPicturesAddressForm #auto_docs').val(auto_docs);
		$('#editPicturesAddressFormCont').show();
		$('#getCampaignsWorkersPicturesListModal').animate({ scrollTop: $('#getCampaignsWorkersPicturesListModal .modal-dialog').height() }, 500);
	},

	fillEditBiAddress: function(auto_docs)
	{
		$('#editBiAddressForm #auto_docs').val(auto_docs);
		$('#editBiAddressFormCont').show();
		document.getElementById('collapseFourMod').scrollIntoView();
	},

	editPicturesAddress: function(myFormDiv)
	{
		$(myFormDiv+' #sender').attr("disabled", true);
		const req = "editPicturesAddress";
		let returns = "";
		let query = $(myFormDiv).serialize();
		query = query + "&id=" + globals.id + "&pwd=" + globals.pwd + "&req=" + req + "&div=" + myFormDiv;
		//$(myFormDiv+' #successfail').append('<div class="alert alert-success" role="alert"><b>Query : '+query+'</b></div>');
		$.post(globals.serverAddressCampaign, query, function(data){
			if(data.ok=="ok") {
				returns = '<div class="alert alert-success" role="alert"><b>Cette adresse a bien été enregistrée / modifiée.</b></div>';
				setTimeout(function(){
					if(myFormDiv=='#editBiAddressForm') App.getCampaignsBiList(globals.currentCampainId);
					else $('#refreshCampaignsWorkersPicturesTable').trigger('click');
					//App.clearFormFields(myFormDiv);
					$(myFormDiv+' #successfail').empty();
					$('#editPicturesAddressFormCont').hide();
				}, 1600);
			}
			else
				returns = '<div class="alert alert-danger" role="alert"><b>Cette adresse n\'a pas été enregistrée / modifiée suite à un problème technique ou aucune modification faites.</b></div>';
		}, "json").always(function(data){
			$(myFormDiv+' #sender').attr("disabled", false);
			$(myFormDiv+' #successfail').empty().append(returns);
		});
	},

	fillEditPicturesCampaign: function(auto_docs, old_name_docs)
	{
		$('#editPicturesCampaignForm #auto_docs').val(auto_docs);
		$('#editPicturesCampaignForm #old_name_docs').val(old_name_docs);
		$('#editPicturesCampaignFormCont').show();
		$('#getCampaignsWorkersPicturesListModal').animate({ scrollTop: $('#getCampaignsWorkersPicturesListModal .modal-dialog').height() }, 500);
	},

	fillEditBiCampaign: function(auto_docs, old_name_docs)
	{
		$('#editBiCampaignForm #auto_docs').val(auto_docs);
		$('#editBiCampaignForm #old_name_docs').val(old_name_docs);
		$('#editBiCampaignFormCont').show();
		document.getElementById('collapseFourMod').scrollIntoView();
	},

	editPicturesCampaign: function(myFormDiv)
	{
		$(myFormDiv+' #sender').attr("disabled", true);
		const req = "editPicturesCampaign";
		let returns = "";
		let query = $(myFormDiv).serialize();
		query = query + "&id=" + globals.id + "&pwd=" + globals.pwd + "&req=" + req;
		//$(myFormDiv+' #successfail').append('<div class="alert alert-success" role="alert"><b>Query : '+query+'</b></div>');
		$.post(globals.serverAddressCampaign, query, function(data){
			if(data.ok=="ok") {
				returns = '<div class="alert alert-success" role="alert"><b>Cette photo a bien été déplacée vers la campagne.</b></div>';
				setTimeout(function(){
					if(myFormDiv=='#editBiCampaignForm') App.getCampaignsBiList(globals.currentCampainId);
					else $('#refreshCampaignsWorkersPicturesTable').trigger('click');
					//App.clearFormFields(myFormDiv);
					$(myFormDiv+' #successfail').empty();
					$('#editPicturesCampaignFormCont').hide();
				}, 1600);
			}
			else
				returns = '<div class="alert alert-danger" role="alert"><b>Cette photo n\'a pas été déplacée vers la campagne suite à un problème technique.</b></div>';
		}, "json").always(function(data){
			$(myFormDiv+' #sender').attr("disabled", false);
			$(myFormDiv+' #successfail').empty().append(returns);
		});
	},

	downloadPicturesPack: function (id_c, thisBtn) {
		$(thisBtn).attr("disabled", true);
		$('#modal_download').modal('show');
		let req = "downloadPicturesPack";
		let query = "&req=" + req + "&id_c=" + id_c + "&id=" + globals.id + "&pwd=" + globals.pwd;
		let returns = "";
		$.post(globals.serverAddressCampaign, query, function(data){
			if(data.ok=="ok") {
				setTimeout(function(){
					// Giving time for the modal to close...
					const a = $("<a>").attr("href", data.link).attr("download", data.name).appendTo("footer");
					a[0].click();
					a.remove();
				}, 600);
			}
			else alert("Il semble qu'il n'y ai pas de photos à télécharger pour cette campagne !");
		}, "json").always(function(data){
			$('#modal_download').modal('hide');
			$(thisBtn).attr("disabled", false);
		}).fail(function(jqXHR, textStatus, errorThrown){
			alert('Téléchargement impossible suite à une erreur technique !\n'+textStatus+'\n'+errorThrown);
			$(thisBtn).attr("disabled", false);
		});
		// Closing downloading popup after 10 seconds anyway
		setTimeout(function(){
			$('#modal_download').modal('hide');
		}, 10000);
	},

	validPicture: function(auto_docs, name_docs, thisBtn)
	{
		$(thisBtn).attr("disabled", true);
		var thisCase = $(thisBtn).closest('td');
		let req = "validPicture";
		let query = "auto_docs=" + auto_docs + "&id=" + globals.id + "&pwd=" + globals.pwd + "&req=" + req;
		//$(myFormDiv+' #successfail').append('<div class="alert alert-success" role="alert"><b>Query : '+query+'</b></div>');
		$.post(globals.serverAddressCampaign, query, function(data){
			if(data.ok=="ok") {
				thisCase.empty().append('<div class="btn-group btn-block" role="group"><button class="btn btn-success col-4 valBtn" onClick="App.unValidPicture(\''+auto_docs+'\', \''+name_docs+'\', this)"><i class="fa fa-check-circle"></i></button><button class="btn btn-info col-4 seenBtn" onClick="App.unSeenPicture(\''+auto_docs+'\', this)"><i class="fa fa-eye"></i></button><button class="btn btn-warning col-4" onClick="App.fillEditPicturesCampaign(\''+auto_docs+'\', \''+name_docs+'\')"  title="Changer cette photo de campagne"><i class="fa fa-retweet"></i></button></div>');
				thisCase.closest('tr').find('a.imgLink').removeClass('btn-outline-danger').addClass('btn-outline-success');
				if(thisCase.closest('tr').hasClass('table-warning')) thisCase.closest('tr').removeClass('table-warning').addClass('table-light');
			}
			else
				alert('Cette photo n\'a pas été validée, suite à un problème technique.');
		}, "json").fail(function(jqXHR, textStatus, errorThrown){
			alert('Cette photo n\'a pas été validée, suite à un problème technique.\n'+textStatus+'\n'+errorThrown);
			$(thisBtn).attr("disabled", false);
		});
	},

	validPictureFromLightBox: function(name_docs)
	{
		let req = "validPictureFromLightBox";
		let query = "name_docs=" + name_docs + "&id=" + globals.id + "&pwd=" + globals.pwd + "&req=" + req;
		$.post(globals.serverAddressCampaign, query, function(data){
			if(data.ok=="ok") {
				//photo_1_325_85_25300_2020-02-20_11:30:34.jpg
				//bi_325_85_25300_2020-02-20_11:30:34.jpg
				let imageInfos = name_docs.split("_");
				let worker, campaign;
				if(imageInfos[0]=='bi') {
					worker = imageInfos[1];
					campaign = imageInfos[2];
				}
				else {
					worker = imageInfos[2];
					campaign = imageInfos[3];
				}
				// let campaign = globals.currentCampainId;
				App.getCampaignsWorkersPicturesList(campaign, worker, false);
				//alert('OK !');
			}
			//else alert('Cette photo n\'a pas été traitée, suite à un problème technique.');
		}, "json").fail(function(jqXHR, textStatus, errorThrown){
			alert('Cette photo n\'a pas été traitée, suite à un problème technique.\n'+textStatus+'\n'+errorThrown);
		});
	},

	unValidPicture: function(auto_docs, name_docs, thisBtn)
	{
		$(thisBtn).attr("disabled", true);
		var thisCase = $(thisBtn).closest('td');
		let req = "unValidPicture";
		let query = "auto_docs=" + auto_docs + "&id=" + globals.id + "&pwd=" + globals.pwd + "&req=" + req;
		//$(myFormDiv+' #successfail').append('<div class="alert alert-success" role="alert"><b>Query : '+query+'</b></div>');
		$.post(globals.serverAddressCampaign, query, function(data){
			if(data.ok=="ok") {
				thisCase.empty().append('<div class="btn-group btn-block" role="group"><button class="btn btn-danger col-4 valBtn" onClick="App.validPicture(\''+auto_docs+'\', \''+name_docs+'\', this)"><i class="fa fa-times-circle"></i></button><button class="btn btn-info col-4 seenBtn" onClick="App.unSeenPicture(\''+auto_docs+'\', this)"><i class="fa fa-eye"></i></button><button class="btn btn-warning col-4" onClick="App.fillEditPicturesCampaign(\''+auto_docs+'\', \''+name_docs+'\')"  title="Changer cette photo de campagne"><i class="fa fa-retweet"></i></button></div>');
				thisCase.closest('tr').find('a.imgLink').removeClass('btn-outline-success').addClass('btn-outline-danger');
				if(thisCase.closest('tr').hasClass('table-warning')) thisCase.closest('tr').removeClass('table-warning').addClass('table-light');
			}
			else
				alert('Cette photo n\'a pas été invalidée, suite à un problème technique.');
		}, "json").fail(function(jqXHR, textStatus, errorThrown){
			alert('Cette photo n\'a pas été invalidée, suite à un problème technique.\n'+textStatus+'\n'+errorThrown);
			$(thisBtn).attr("disabled", false);
		});
	},

	seenPicture: function(auto_docs, thisBtn)
	{
		$(thisBtn).attr("disabled", true);
		var thisCase = $(thisBtn).closest('td');
		var thisBtnGroup = $(thisBtn).closest('.btn-group');
		let req = "seenPicture";
		let query = "auto_docs=" + auto_docs + "&id=" + globals.id + "&pwd=" + globals.pwd + "&req=" + req;
		//$(myFormDiv+' #successfail').append('<div class="alert alert-success" role="alert"><b>Query : '+query+'</b></div>');
		$.post(globals.serverAddressCampaign, query, function(data){
			if(data.ok=="ok") {
				$(thisBtn).remove();
				thisBtnGroup.append('<button class="btn btn-info seenBtn" onClick="App.unSeenPicture('+auto_docs+', this)"><i class="fa fa-eye"></i></button>');
				thisCase.closest('tr').removeClass('table-warning').addClass('table-light');
			}
			else
				alert('Cette photo n\'a pas été traitée, suite à un problème technique.');
		}, "json").fail(function(jqXHR, textStatus, errorThrown){
			alert('Cette photo n\'a pas été traitée, suite à un problème technique.\n'+textStatus+'\n'+errorThrown);
			$(thisBtn).attr("disabled", false);
		});
	},

	seenPictureFromLightBox: function(name_docs)
	{
		let req = "seenPictureFromLightBox";
		let query = "name_docs=" + name_docs + "&id=" + globals.id + "&pwd=" + globals.pwd + "&req=" + req;
		$.post(globals.serverAddressCampaign, query, function(data){
			if(data.ok=="ok") {
				let imageInfos = name_docs.split("_");
				let worker = imageInfos[2];
				let campaign = imageInfos[3];
				App.getCampaignsWorkersPicturesList(campaign, worker, false);
				App.getCampaignsPicturesList(campaign);
				//App.getCampaignsPicturesList(globals.currentCampainId);
				//alert('OK !');
			}
			//else alert('Cette photo n\'a pas été traitée, suite à un problème technique.');
		}, "json").fail(function(jqXHR, textStatus, errorThrown){
			alert('Cette photo n\'a pas été traitée, suite à un problème technique.\n'+textStatus+'\n'+errorThrown);
		});
	},

	unSeenPicture: function(auto_docs, thisBtn)
	{
		$(thisBtn).attr("disabled", true);
		var thisCase = $(thisBtn).closest('td');
		var thisBtnGroup = $(thisBtn).closest('.btn-group');
		let req = "unSeenPicture";
		let query = "auto_docs=" + auto_docs + "&id=" + globals.id + "&pwd=" + globals.pwd + "&req=" + req;
		//$(myFormDiv+' #successfail').append('<div class="alert alert-success" role="alert"><b>Query : '+query+'</b></div>');
		$.post(globals.serverAddressCampaign, query, function(data){
			if(data.ok=="ok") {
				$(thisBtn).remove();
				thisBtnGroup.append('<button class="btn btn-dark seenBtn" onClick="App.seenPicture('+auto_docs+', this)"><i class="fa fa-eye-slash"></i></button>');
				thisCase.closest('tr').removeClass('table-light').addClass('table-warning');
			}
			else
				alert('Cette photo n\'a pas été traitée, suite à un problème technique.');
		}, "json").fail(function(jqXHR, textStatus, errorThrown){
			alert('Cette photo n\'a pas été traitée, suite à un problème technique.\n'+textStatus+'\n'+errorThrown);
			$(thisBtn).attr("disabled", false);
		});
	},

	blockWorker: function(auto_p, name_p, login_p, contact_p, phone_p, pwd_p, thisBtn)
	{
		const confirmBlock = confirm("Êtes-vous certain de vouloir bloquer ce Médiaplicateur ?\n"+name_p+" - "+contact_p);
		if (confirmBlock) {
			$(thisBtn).attr("disabled", true);
			var thisCase = $(thisBtn).closest('td');
			var thisRow = $(thisBtn).closest('tr');
			let req = "blockWorker";
			let query = "auto_p=" + auto_p + "&name_p=" + name_p + "&login_p=" + login_p + "&contact_p=" + contact_p + "&phone_p=" + phone_p + "&pwd_p=" + pwd_p + "&id=" + globals.id + "&pwd=" + globals.pwd + "&req=" + req;
			//$(myFormDiv+' #successfail').append('<div class="alert alert-success" role="alert"><b>Query : '+query+'</b></div>');
			$.post(globals.serverAddressAdministratives, query, function(data){
				if(data.ok=="ok") {
					thisCase.closest('td').empty().append('<div class="btn-group btn-block" role="group"><button class="btn btn-dark" title="Voir les Assignations" onClick="App.showAssignCampWorker(\''+auto_p+'\', \''+name_p+'\', this)"><i class="fa fa-2x fa-list-ol"></i></button><button class="btn btn-info" id="getWorkersDocumentsListBtn_'+auto_p+'" title="Voir les Documents" onClick="App.getWorkersDocumentsList(\''+auto_p+'\', this)"><i class="fa fa-2x fa-folder-open"></i></button><button class="btn btn-danger" title="Débloquer" onClick="App.unBlockWorker(\''+auto_p+'\', \''+name_p+'\', \''+login_p+'\', \''+contact_p+'\', \''+phone_p+'\', \''+pwd_p+'\', this)"><i class="fa fa-2x fa-link"></i></button><button class="btn btn-success" title="Renvoyer le mot de passe" onClick="App.sendPwdToWorker(\''+auto_p+'\', \''+name_p+'\', \''+login_p+'\', \''+contact_p+'\', \''+phone_p+'\', \''+pwd_p+'\', this)"><i class="fa fa-2x fa-key"></i></button></div>');
					thisCase.prev('td').empty().append('Oui');
					thisRow.addClass('table-danger');
				}
				else {
					alert('Ce Médiaplicateur n\'a pas été assigné à cette campagne, suite à un problème technique.');
					$(thisBtn).attr("disabled", false);
				}
			}, "json").fail(function(jqXHR, textStatus, errorThrown){
				alert('Ce Médiaplicateur n\'a pas été assigné à cette campagne, suite à un problème technique.\n'+textStatus+'\n'+errorThrown);
				$(thisBtn).attr("disabled", false);
			});
		}
	},

	unBlockWorker: function(auto_p, name_p, login_p, contact_p, phone_p, pwd_p, thisBtn)
	{
		$(thisBtn).attr("disabled", true);
		var thisCase = $(thisBtn).closest('td');
		var thisRow = $(thisBtn).closest('tr');
		let req = "unBlockWorker";
		let query = "auto_p=" + auto_p + "&name_p=" + name_p + "&login_p=" + login_p + "&contact_p=" + contact_p + "&phone_p=" + phone_p + "&pwd_p=" + pwd_p + "&id=" + globals.id + "&pwd=" + globals.pwd + "&req=" + req;
		//$(myFormDiv+' #successfail').append('<div class="alert alert-success" role="alert"><b>Query : '+query+'</b></div>');
		$.post(globals.serverAddressAdministratives, query, function(data){
			if(data.ok=="ok") {
				thisCase.empty().append('<div class="btn-group btn-block" role="group"><button class="btn btn-dark" title="Voir les Assignations" onClick="App.showAssignCampWorker(\''+auto_p+'\', \''+name_p+'\', this)"><i class="fa fa-2x fa-list-ol"></i></button><button class="btn btn-info" id="getWorkersDocumentsListBtn_'+auto_p+'" title="Voir les Documents" onClick="App.getWorkersDocumentsList(\''+auto_p+'\', this)"><i class="fa fa-2x fa-folder-open"></i></button><button class="btn btn-warning" title="Bloquer" onClick="App.blockWorker(\''+auto_p+'\', \''+name_p+'\', \''+login_p+'\', \''+contact_p+'\', \''+phone_p+'\', \''+pwd_p+'\', this)"><i class="fa fa-2x fa-unlink"></i></button><button class="btn btn-success" title="Renvoyer le mot de passe" onClick="App.sendPwdToWorker(\''+auto_p+'\', \''+name_p+'\', \''+login_p+'\', \''+contact_p+'\', \''+phone_p+'\', \''+pwd_p+'\', this)"><i class="fa fa-2x fa-key"></i></button></div>');
				thisCase.prev('td').empty().append('Non');
				thisRow.removeClass('table-danger');
			}
			else {
				alert('Ce Médiaplicateur n\'a pas été désassigné à cette campagne, suite à un problème technique.');
				$(thisBtn).attr("disabled", false);
			}
		}, "json").fail(function(jqXHR, textStatus, errorThrown){
			alert('Ce Médiaplicateur n\'a pas été désassigné à cette campagne, suite à un problème technique.\n'+textStatus+'\n'+errorThrown);
			$(thisBtn).attr("disabled", false);
		});
	},

	sendPwdToWorker: function(auto_p, name_p, login_p, contact_p, phone_p, pwd_p, thisBtn)
	{
		const confirmBlock = confirm("Renvoyer ses identifiants à ce Médiaplicateur ?\n"+name_p+" - "+contact_p);
		if (confirmBlock) {
			$(thisBtn).attr("disabled", true);
			let req = "sendPwdToWorker";
			let query = "auto_p=" + auto_p + "&name_p=" + name_p + "&login_p=" + login_p + "&contact_p=" + contact_p + "&phone_p=" + phone_p + "&pwd_p=" + pwd_p + "&id=" + globals.id + "&pwd=" + globals.pwd + "&req=" + req;
			//$(myFormDiv+' #successfail').append('<div class="alert alert-success" role="alert"><b>Query : '+query+'</b></div>');
			$.post(globals.serverAddressAdministratives, query, function(data){
				if(data.ok=="ok") {
					alert('Les identifiants ont bien été envoyés.');
				}
				else {
					alert('Ce Médiaplicateur n\'a pas été contacté, suite à un problème technique.');
				}
				$(thisBtn).attr("disabled", false);
			}, "json").fail(function(jqXHR, textStatus, errorThrown){
				alert('Ce Médiaplicateur n\'a pas été contacté, suite à un problème technique.\n'+textStatus+'\n'+errorThrown);
				$(thisBtn).attr("disabled", false);
			});
		}
	},

	validDocument: function(auto_ad, field_ad, thisBtn)
	{
		$(thisBtn).attr("disabled", true);
		var thisCase = $(thisBtn).closest('td');
		let req = "validDocument";
		let query = "auto_ad=" + auto_ad + "&field_ad=" + field_ad + "&id=" + globals.id + "&pwd=" + globals.pwd + "&req=" + req;
		//$(myFormDiv+' #successfail').append('<div class="alert alert-success" role="alert"><b>Query : '+query+'</b></div>');
		$.post(globals.serverAddressAdministratives, query, function(data){
			if(data.ok=="ok") {
				thisCase.empty().append('<button class="btn btn-success btn-block valBtn" onClick="App.unValidDocument(\''+auto_ad+'\', \''+field_ad+'\', this)"><i class="fa fa-times-circle"></i> Invalider</button>');
				thisCase.closest('tr').find('a.imgLink').removeClass('btn-outline-danger').removeClass('btn-outline-info').addClass('btn-outline-success');
				thisCase.closest('tr').removeClass('table-danger').removeClass('table-info').addClass('table-success');
				//if(thisCase.closest('tr').hasClass('table-warning')) thisCase.closest('tr').removeClass('table-warning').addClass('table-light');
				App.getWorkersDocsList();
			}
			else
				alert('Cette photo n\'a pas été validée, suite à un problème technique.');
		}, "json").fail(function(jqXHR, textStatus, errorThrown){
			alert('Cette photo n\'a pas été validée, suite à un problème technique.\n'+textStatus+'\n'+errorThrown);
			$(thisBtn).attr("disabled", false);
		});
	},

	validDocumentFromLightBox: function(name_docs)
	{
		let req = "validDocumentFromLightBox";
		var field_ad_temp = name_docs.split("_");
		let field_ad = field_ad_temp[0]+"_"+field_ad_temp[1];
		let query = "name_docs=" + name_docs + "&field_ad=" + field_ad + "&id=" + globals.id + "&pwd=" + globals.pwd + "&req=" + req;
		$.post(globals.serverAddressAdministratives, query, function(data){
			if(data.ok=="ok") {
				//assu_325_2020-02-20_11:30:34.jpg
				let imageInfos = name_docs.split("_");
				let worker = imageInfos[2];
				//let zipDoc = imageInfos[3];
				//let dateDoc = imageInfos[4];
				//let timeDoc = imageInfos[5];
				App.getWorkersDocumentsList(worker, false);
				App.getWorkersDocsList();
				//alert('OK !');
			}
			//else alert('Cette photo n\'a pas été traitée, suite à un problème technique.');
		}, "json").fail(function(jqXHR, textStatus, errorThrown){
			alert('Cette photo n\'a pas été traitée, suite à un problème technique.\n'+textStatus+'\n'+errorThrown);
		});
	},

	unValidDocument: function(auto_ad, field_ad, thisBtn)
	{
		$(thisBtn).attr("disabled", true);
		var thisCase = $(thisBtn).closest('td');
		let req = "unValidDocument";
		let query = "auto_ad=" + auto_ad + "&field_ad=" + field_ad + "&id=" + globals.id + "&pwd=" + globals.pwd + "&req=" + req;
		//$(myFormDiv+' #successfail').append('<div class="alert alert-success" role="alert"><b>Query : '+query+'</b></div>');
		$.post(globals.serverAddressAdministratives, query, function(data){
			if(data.ok=="ok") {
				thisCase.empty().append('<button class="btn btn-danger btn-block valBtn" onClick="App.validDocument(\''+auto_ad+'\', \''+field_ad+'\', this)"><i class="fa fa-check-circle"></i> Valider</button>');
				thisCase.closest('tr').find('a.imgLink').removeClass('btn-outline-success').addClass('btn-outline-danger');
				thisCase.closest('tr').removeClass('table-success').addClass('table-danger');
				// if(thisCase.closest('tr').hasClass('table-warning')) thisCase.closest('tr').removeClass('table-warning').addClass('table-light');
				App.getWorkersDocsList();
			}
			else
				alert('Cette photo n\'a pas été invalidée, suite à un problème technique.');
		}, "json").fail(function(jqXHR, textStatus, errorThrown){
			alert('Cette photo n\'a pas été invalidée, suite à un problème technique.\n'+textStatus+'\n'+errorThrown);
			$(thisBtn).attr("disabled", false);
		});
	},

	fillEditDocumentsDates: function(auto_ad, field_ad)
	{
		$('#editDocumentsDatesForm #auto_ad').val(auto_ad);
		$('#editDocumentsDatesForm #field_ad').val(field_ad);
		$('#editDocumentsDatesFormCont').show();
		$('#getWorkersDocumentsListModal').animate({ scrollTop: $('#getCampaignsWorkersPicturesListModal .modal-dialog').height() }, 500);
	},

	editDocumentsDates: function(myFormDiv)
	{
		$(myFormDiv+' #sender').attr("disabled", true);
		//let query = $(myFormDiv).serialize();
		let req = "editDocumentsDates";
		let returns = "";
		let query = $(myFormDiv).serialize();
		query = query + "&id=" + globals.id + "&pwd=" + globals.pwd + "&req=" + req;
		//$(myFormDiv+' #successfail').append('<div class="alert alert-success" role="alert"><b>Query : '+query+'</b></div>');
		$.post(globals.serverAddressAdministratives, query, function(data){
			if(data.ok=="ok") {
				returns = '<div class="alert alert-success" role="alert"><b>Cette date a bien été enregistrée / modifiée.</b></div>';
				setTimeout(function(){
					$('#refreshWorkersDocumentsListTable').trigger('click');
					App.clearFormFields(myFormDiv);
					$(myFormDiv+' #successfail').empty();
					$('#editDocumentsDatesFormCont').hide();
				}, 1600);
			}
			else
				returns = '<div class="alert alert-danger" role="alert"><b>Cette date n\'a pas été enregistrée / modifiée suite à un problème technique ou aucune modification faites.</b></div>';
		}, "json").always(function(data){
			$(myFormDiv+' #sender').attr("disabled", false);
			$(myFormDiv+' #successfail').empty().append(returns);
		});
	},

	cancelDocumentsDatesForm: function(myFormDiv, event)
	{
		event.preventDefault();
		App.clearFormFields(myFormDiv);
		$('#editDocumentsDatesFormCont').hide();
	},

	changeCalendarRatio: function(thisBtn)
	{
		if($('#calendarCont').is('.embed-responsive-1by1')) {
			$('#calendarCont').removeClass('embed-responsive-1by1').addClass('embed-responsive-16by9');
			$(thisBtn).children('i').removeClass('fa-expand-arrows-alt').addClass('fa-arrows-alt');
		}
		else {
			$('#calendarCont').removeClass('embed-responsive-16by9').addClass('embed-responsive-1by1');
			$(thisBtn).children('i').removeClass('fa-arrows-alt').addClass('fa-expand-arrows-alt');
		}
	},

	switchCalendar: function(thisBtn, agenda)
	{
		if(agenda=='seller') {
			$('#calendarFrame').attr('src', 'https://calendar.google.com/calendar/embed?height=600&wkst=2&bgcolor=%23F6BF26&ctz=Europe%2FParis&src=cjIxNXF2dG90Y3BmbjNrcThhaG5rdDRmaGtAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&src=ZnIuZnJlbmNoI2hvbGlkYXlAZ3JvdXAudi5jYWxlbmRhci5nb29nbGUuY29t&src=bDQ4ZDZoOWRxajE3Zm1iaDE0bjY0ZGF1cm9AZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&color=%23F6BF26&color=%2333B679&color=%237CB342&showTz=0&title=Commercial');
			$(thisBtn).children('i').removeClass('fa-toggle-off').addClass('fa-toggle-on');
			$(thisBtn).attr('onclick', "App.switchCalendar(this, 'planning')");
		}
		else {
			$('#calendarFrame').attr('src', 'https://calendar.google.com/calendar/embed?height=600&wkst=2&bgcolor=%234285F4&ctz=Europe%2FParis&src=cmVzb3BsYW5uaW5nQGdtYWlsLmNvbQ&src=OGgxZGoyZ3EyaG52NGprbmsyOXJvZWs1am9AZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&src=Y3YxZmM0NmM4bmE3aWg2dWgxMDcxZmQxMmNAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&src=b21jcjQ2dHRsOTEwbGVrbWZ0dXAzaHV1N2NAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&src=dGFhcmo3aXE0NWhuaGZkMG1pcGE0ZXRjaWNAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&src=dmZubDFuNWFodTl0NTJsNXFtMGY4MGhldThAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&src=MTNvNmhtZDVjc3Q4cXN0cTVybmlmbjljaDBAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&src=Nm1haG50c3E4YXQyNDIwc2VsZ3VnNTNwZ2dAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&color=%23009688&color=%23F09300&color=%23D50000&color=%238E24AA&color=%237CB342&color=%23D81B60&color=%23616161&color=%234285F4&showTitle=0');
			$(thisBtn).children('i').removeClass('fa-toggle-on').addClass('fa-toggle-off');
			$(thisBtn).attr('onclick', "App.switchCalendar(this, 'seller')");
		}
	},

	getProspectsList: function() {
		$.post(globals.serverAddressCommercial, {id: globals.id, type: globals.type, pwd: globals.pwd, req: 'getProspectsList'}, function(data){
			$("#tabProspectsCont").empty().append(data.snippet);
			//$.localStorage.setItem('workers', data.workers);
			//globals.workers = data.workers;
		}, "json").done(function() {
			//$('table').addClass('tablesorter');
			$('.tablesorterProspects').tablesorter({
				theme: 'bootstrap', // dark or bootstrap are cool here.
				dateFormat : "ddmmyyyy", // set the default date format
				// hidden filter input/selects will resize the columns, so try to minimize the change
				widthFixed : true,
				// initialize zebra striping and filter widgets
				widgets: ["zebra", "filter", "cssStickyHeaders"],
				ignoreCase: false,
				widgetOptions : {
					// See options at : https://mottie.github.io/tablesorter/docs/example-widget-filter.html
					filter_childRows : false,
					filter_columnFilters : true,
					filter_cellFilter : '',
					filter_defaultFilter : {},
					filter_excludeFilter : {},
					filter_external : '',
					filter_filteredRow : 'filtered',
					filter_formatter : null,
					filter_functions : null,
					filter_hideEmpty : true,
					filter_hideFilters : false,
					filter_ignoreCase : true,
					filter_liveSearch : true,
					filter_startsWith : false,
					filter_onlyAvail : 'filter-onlyAvail',
					filter_placeholder : { search : 'Filtre', select : '' },
					filter_reset : 'button.reset',
					filter_saveFilters : false,
					filter_searchDelay : 300,
					filter_searchFiltered: true,
					filter_selectSource  : null,
					filter_serversideFiltering : false,
					filter_useParsedData : false,
					filter_defaultAttrib : 'data-value',
					filter_selectSourceSeparator : '|',
					// if false and a caption exist, it won't be included in the sticky header
					cssStickyHeaders_addCaption: true,
					// jQuery selector or object to attach sticky header to
					cssStickyHeaders_attachTo: null,
					// number or jquery selector targeting the position:fixed element
					cssStickyHeaders_offset: 70,
					// scroll table top into view after filtering
					cssStickyHeaders_filteredToTop : true,
					// extra css class name (string or array) added to the filter element (input or select)
					filter_cssFilter: [
						'form-control',
						'form-control',
						'form-control',
						'form-control',
						'form-control',
						'form-control',
						'form-control',
						'form-control custom-select', // select needs custom class names :(
						'form-control',
						'form-control',
						'form-control',
						'form-control',
						'form-control custom-select', // select needs custom class names :(
					]
				}
			}).tablesorterPager({
				container: $('#ProspectsPager'),
				size: 20
			});
		});
	},

	fillModProspect: function(auto_pr, name_pr, mail_pr, phone1_pr, phone2_pr, address_pr, zip_pr, city_pr, status_pr, amount_pr, date_first_pr, date_last_pr, date_next_pr, date_creation_pr, comments_pr, active_pr)
	{
		$('#modFormProspects #auto_pr').val(auto_pr);
		$('#modFormProspects #name_pr').val(name_pr);
		$('#modFormProspects #mail_pr').val(mail_pr);
		$('#modFormProspects #phone1_pr').val(phone1_pr);
		$('#modFormProspects #phone2_pr').val(phone2_pr);
		$('#modFormProspects #address_pr').val(address_pr);
		$('#modFormProspects #zip_pr').val(zip_pr);
		$('#modFormProspects #city_pr').val(city_pr);
		$('#modFormProspects #status_pr').val(status_pr);
		$('#modFormProspects #amount_pr').val(amount_pr);
		$('#modFormProspects #date_first_pr').val(date_first_pr);
		$('#modFormProspects #date_last_pr').val(date_last_pr);
		$('#modFormProspects #date_next_pr').val(date_next_pr);
		$('#modFormProspects #date_creation_pr').val(date_creation_pr);
		$('#modFormProspects #comments_pr').val(comments_pr.replace(/  /gi, "\r\n")); // Put back chariot's returns.
		$('#modFormProspects #active_pr').val(active_pr);
		$('#modProspectsModal').modal('show');
	},

	modProspect: function(myFormDiv)
	{
		$(myFormDiv+' #sender').attr("disabled", true);
		let query = $(myFormDiv).serialize();
		let req = "modProspect";
		query = query + "&id=" + globals.id + "&pwd=" + globals.pwd + "&req=" + req;
		let returns = "";
		//$(myFormDiv+' #successfail').append('<div class="alert alert-success" role="alert"><b>Query : '+query+'</b></div>');
		$.post(globals.serverAddressCommercial, query, function(data){
			if(data.ok=="ok") {
				returns = '<div class="alert alert-success" role="alert"><b>Cette fiche Prospect a bien été modifiée.</b></div>';
				App.getProspectsList();
				setTimeout(function(){
					$(myFormDiv).closest('.modal').modal('hide');
					App.clearFormFields(myFormDiv);
				}, 1600);
			}
			else
				returns = '<div class="alert alert-danger" role="alert"><b>Cette fiche Prospect n\'a pas été modifiée suite à un problème technique.</b></div>';
		}, "json").always(function(data){
			$(myFormDiv+' #sender').attr("disabled", false);
			$(myFormDiv+' #successfail').empty().append(returns);
		});
	},

	addProspect: function(myFormDiv)
	{
		$(myFormDiv+' #sender').attr("disabled", true);
		let query = $(myFormDiv).serialize();
		let req = "addProspect";
		query = query + "&id=" + globals.id + "&pwd=" + globals.pwd + "&name_u=" + globals.name + "&phone_u=" + globals.phone + "&req=" + req;
		let returns = "";
		//$(myFormDiv+' #successfail').append('<div class="alert alert-success" role="alert"><b>Query : '+query+'</b></div>');
		$.post(globals.serverAddressCommercial, query, function(data){
			if(data.ok=="ok") {
				returns = '<div class="alert alert-success" role="alert"><b>Cette fiche Prospect a bien été ajoutée à votre carnet d\'adresse.</b></div>';
				App.getProspectsList();
				setTimeout(function(){
					$(myFormDiv).closest('.modal').modal('hide');
					App.clearFormFields(myFormDiv);
				}, 1600);
			}
			else
				returns = '<div class="alert alert-danger" role="alert"><b>Cette fiche Prospect n\'a pas été ajoutée à votre carnet d\'adresse, suite à un problème technique.</b></div>';
		}, "json").always(function(data){
			$(myFormDiv+' #sender').attr("disabled", false);
			$(myFormDiv+' #successfail').empty().append(returns);
		});
	},

	delProspect: function(myFormDiv, event)
	{
		event.preventDefault(); // prevents mod form submission !
		const confirmDeletion = confirm("Êtes-vous certain de vouloir supprimer cette fiche ?");
		if (confirmDeletion) {
			$(myFormDiv+' #deleter').attr("disabled", true);
			let delId = $(myFormDiv+' #auto_cl').val();
			let req = "delProspect";
			let query = "auto_cl=" + delId + "&id=" + globals.id + "&pwd=" + globals.pwd + "&req=" + req;
			let returns = "";
			//$(myFormDiv+' #successfail').append('<div class="alert alert-success" role="alert"><b>Query : '+query+'</b></div>');
			$.post(globals.serverAddressCommercial, query, function(data){
				if(data.ok=="ok") {
					returns = '<div class="alert alert-success" role="alert"><b>Cette fiche Prospect a bien été supprimée.</b></div>';
					App.getProspectsList();
					setTimeout(function(){
						$(myFormDiv).closest('.modal').modal('hide');
						App.clearFormFields(myFormDiv);
					}, 1600);
				}
				else
					returns = '<div class="alert alert-danger" role="alert"><b>Cette fiche Prospect n\'a pas été supprimée suite à un problème technique.</b></div>';
			}, "json").always(function(data){
				$(myFormDiv+' #deleter').attr("disabled", false);
				$(myFormDiv+' #successfail').empty().append(returns);
			});
		}
	},

	showProspectAppointments: function(auto_pr, name_pr, thisBtn)
	{
		$(thisBtn).attr("disabled", true);
		$('#showProspectAppointmentsModal #prospectNameTag').empty().append(name_pr);
		$('#showProspectAppointmentsModal').modal('show');
		$.post(globals.serverAddressCommercial, {auto_pr: auto_pr, id: globals.id, type: globals.type, pwd: globals.pwd, req: 'showProspectAppointments'}, function(data){
			if(data.ok=="ok") {
				$("#tabProspectAppointmentsCont").empty().append(data.snippet);
			}
			else {
				$("#tabProspectAppointmentsCont").empty().append(data.snippet);
				alert('Ce Prospect n\'a pas de RDV programmé actuellement.');
			}
		}, "json").done(function() {
			//$('table').addClass('tablesorter');
			$('.tablesorterProspectAppointments').tablesorter({
				theme: 'bootstrap', // dark or bootstrap are cool here.
				dateFormat : "ddmmyyyy", // set the default date format
				// hidden filter input/selects will resize the columns, so try to minimize the change
				widthFixed : true,
				// initialize zebra striping and filter widgets
				//widgets: ["zebra", "filter"],
				widgets: ["filter", "zebra", "cssStickyHeaders"],
				ignoreCase: false,
				widgetOptions : {
					// See options at : https://mottie.github.io/tablesorter/docs/example-widget-filter.html
					filter_childRows : false,
					filter_columnFilters : true,
					filter_cellFilter : '',
					filter_defaultFilter : {},
					filter_excludeFilter : {},
					filter_external : '',
					filter_filteredRow : 'filtered',
					filter_formatter : null,
					filter_functions : null,
					filter_hideEmpty : true,
					filter_hideFilters : false,
					filter_ignoreCase : true,
					filter_liveSearch : true,
					filter_startsWith : false,
					filter_onlyAvail : 'filter-onlyAvail',
					filter_placeholder : { search : 'Filtre', select : '' },
					filter_reset : 'button.reset',
					filter_saveFilters : false,
					filter_searchDelay : 300,
					filter_searchFiltered: true,
					filter_selectSource  : null,
					filter_serversideFiltering : false,
					filter_useParsedData : false,
					filter_defaultAttrib : 'data-value',
					filter_selectSourceSeparator : '|',
					// if false and a caption exist, it won't be included in the sticky header
					cssStickyHeaders_addCaption: true,
					// jQuery selector or object to attach sticky header to
					cssStickyHeaders_attachTo: null,
					// number or jquery selector targeting the position:fixed element
					cssStickyHeaders_offset: 70,
					// scroll table top into view after filtering
					cssStickyHeaders_filteredToTop : false,
					// extra css class name (string or array) added to the filter element (input or select)
					filter_cssFilter: []
				}
			}).tablesorterPager({
				container: $('#showProspectAppointmentsPager'),
				size: 20
			});
		}).fail(function(jqXHR, textStatus, errorThrown){
			alert('Il y a un problème technique.\n'+textStatus+'\n'+errorThrown);
		}).always(function(data){
			$(thisBtn).attr("disabled", false);
		});
	},

	getAppointmentsList: function() {
		$.post(globals.serverAddressCommercial, {id: globals.id, type: globals.type, pwd: globals.pwd, req: 'getAppointmentsList'}, function(data){
			$("#tabAppointmentsCont").empty().append(data.snippet);
			//$.localStorage.setItem('workers', data.workers);
			//globals.workers = data.workers;
		}, "json").done(function() {
			//$('table').addClass('tablesorter');
			$('.tablesorterAppointments').tablesorter({
				theme: 'bootstrap', // dark or bootstrap are cool here.
				dateFormat : "ddmmyyyy", // set the default date format
				// hidden filter input/selects will resize the columns, so try to minimize the change
				widthFixed : true,
				// initialize widgets
				// widgets: ["zebra", "filter", "cssStickyHeaders"],
				widgets: ["filter", "cssStickyHeaders"],
				ignoreCase: false,
				widgetOptions : {
					// See options at : https://mottie.github.io/tablesorter/docs/example-widget-filter.html
					filter_childRows : false,
					filter_columnFilters : true,
					filter_cellFilter : '',
					filter_defaultFilter : {},
					filter_excludeFilter : {},
					filter_external : '',
					filter_filteredRow : 'filtered',
					filter_formatter : null,
					filter_functions : null,
					filter_hideEmpty : true,
					filter_hideFilters : false,
					filter_ignoreCase : true,
					filter_liveSearch : true,
					filter_startsWith : false,
					filter_onlyAvail : 'filter-onlyAvail',
					filter_placeholder : { search : 'Filtre', select : '' },
					filter_reset : 'button.reset',
					filter_saveFilters : false,
					filter_searchDelay : 300,
					filter_searchFiltered: true,
					filter_selectSource  : null,
					filter_serversideFiltering : false,
					filter_useParsedData : false,
					filter_defaultAttrib : 'data-value',
					filter_selectSourceSeparator : '|',
					// if false and a caption exist, it won't be included in the sticky header
					cssStickyHeaders_addCaption: true,
					// jQuery selector or object to attach sticky header to
					cssStickyHeaders_attachTo: null,
					// number or jquery selector targeting the position:fixed element
					cssStickyHeaders_offset: 70,
					// scroll table top into view after filtering
					cssStickyHeaders_filteredToTop : true,
					// extra css class name (string or array) added to the filter element (input or select)
					filter_cssFilter: [
						'form-control',
						'form-control',
						'form-control',
						'form-control',
						'form-control',
						'form-control custom-select', // select needs custom class names :(
						'form-control custom-select', // select needs custom class names :(
						'form-control',
						'form-control custom-select', // select needs custom class names :(
					]
				}
			}).tablesorterPager({
				container: $('#AppointmentsPager'),
				size: 20
			});
		});
	},

	fillAddAppointment: function(auto_pr, name_pr, mail_pr, address_pr)
	{
		$('#addAppointmentModal #prospectNameTag').empty().append(name_pr);
		$('#addAppointment #auto_pr').val(auto_pr);
		$('#addAppointment #name_pr').val(name_pr);
		$('#addAppointment #mail_pr').val(mail_pr);
		$('#addAppointment #address_pr').val(address_pr);
		$('#addAppointmentModal').modal('show');
	},

	fillModAppointment: function(auto_pr, name_pr, mail_pr, auto_pe, event_id_pe, name_pe, comments_pe, date_start_pe, date_end_pe, type_pe, status_pe, address_pe, address_pr)
	{
		$('#modAppointmentModal #prospectNameTag').empty().append(name_pr);
		$('#modAppointment #auto_pr').val(auto_pr);
		$('#modAppointment #name_pr').val(name_pr);
		$('#modAppointment #mail_pr').val(mail_pr);
		$('#modAppointment #auto_pe').val(auto_pe);
		$('#modAppointment #event_id_pe').val(event_id_pe);
		$('#modAppointment #name_pe').val(name_pe);
		$('#modAppointment #comments_pe').val(comments_pe.replace(/  /gi, "\r\n")); // Put back chariot's returns.
		$('#modAppointment #date_start_pe').val(date_start_pe);
		$('#modAppointment #date_end_pe').val(date_end_pe);
		$('#modAppointment #type_pe').val(type_pe);
		$('#modAppointment #status_pe').val(status_pe);
		$('#modAppointment #address_pe').val(address_pe);
		$('#modAppointment #address_pr').val(address_pr);
		$('#modAppointmentModal').modal('show');
	},

	modAppointment: function(myFormDiv)
	{
		$(myFormDiv+' #sender').attr("disabled", true);
		let query = $(myFormDiv).serialize();
		let req = "modAppointment";
		query = query + "&id=" + globals.id + "&calendarId=" + globals.calendar + "&name_u=" + globals.name + "&phone_u=" + globals.phone + "&pwd=" + globals.pwd + "&req=" + req;
		let returns = "";
		//$(myFormDiv+' #successfail').append('<div class="alert alert-success" role="alert"><b>Query : '+query+'</b></div>');
		$.post(globals.serverAddressCommercial, query, function(data){
			if(data.ok=="ok") {
				returns = '<div class="alert alert-success" role="alert"><b>Ce RDV a bien été modifiée.</b></div>';
				App.getAppointmentsList();
				setTimeout(function(){
					$(myFormDiv).closest('.modal').modal('hide');
					App.clearFormFields(myFormDiv);
				}, 1600);
			}
			else
				returns = '<div class="alert alert-danger" role="alert"><b>Ce RDV n\'a pas été modifiée suite à un problème technique.</b></div>';
		}, "json").always(function(data){
			$(myFormDiv+' #sender').attr("disabled", false);
			$(myFormDiv+' #successfail').empty().append(returns);
		});
	},

	addAppointment: function(myFormDiv)
	{
		$(myFormDiv+' #sender').attr("disabled", true);
		let query = $(myFormDiv).serialize();
		let req = "addAppointment";
		query = query + "&id=" + globals.id + "&calendarId=" + globals.calendar + "&name_u=" + globals.name + "&phone_u=" + globals.phone + "&pwd=" + globals.pwd + "&req=" + req;
		let returns = "";
		//$(myFormDiv+' #successfail').append('<div class="alert alert-success" role="alert"><b>Query : '+query+'</b></div>');
		$.post(globals.serverAddressCommercial, query, function(data){
			if(data.ok=="ok") {
				returns = '<div class="alert alert-success" role="alert"><b>Ce RDV a bien été ajoutée à votre agenda.</b></div>';
				App.getAppointmentsList();
				setTimeout(function(){
					$(myFormDiv).closest('.modal').modal('hide');
					App.clearFormFields(myFormDiv);
				}, 1600);
			}
			else
				returns = '<div class="alert alert-danger" role="alert"><b>Ce RDV n\'a pas été ajoutée à votre agenda, suite à un problème technique.</b></div>';
		}, "json").always(function(data){
			$(myFormDiv+' #sender').attr("disabled", false);
			$(myFormDiv+' #successfail').empty().append(returns);
		});
	},

	delAppointment: function(myFormDiv, event)
	{
		event.preventDefault(); // prevents mod form submission !
		const confirmDeletion = confirm("Êtes-vous certain de vouloir supprimer ce RDV ?");
		if (confirmDeletion) {
			$(myFormDiv+' #deleter').attr("disabled", true);
			let delId = $(myFormDiv+' #auto_pe').val();
			let req = "delAppointment";
			let query = "auto_pe=" + delId + "&calendarId=" + globals.calendar + "&name_u=" + globals.name + "&phone_u=" + globals.phone + "&id=" + globals.id + "&pwd=" + globals.pwd + "&req=" + req;
			let returns = "";
			//$(myFormDiv+' #successfail').append('<div class="alert alert-success" role="alert"><b>Query : '+query+'</b></div>');
			$.post(globals.serverAddressCommercial, query, function(data){
				if(data.ok=="ok") {
					returns = '<div class="alert alert-success" role="alert"><b>Ce RDV a bien été supprimée.</b></div>';
					App.getAppointmentsList();
					setTimeout(function(){
						$(myFormDiv).closest('.modal').modal('hide');
						App.clearFormFields(myFormDiv);
					}, 1600);
				}
				else
					returns = '<div class="alert alert-danger" role="alert"><b>Ce RDV n\'a pas été supprimée suite à un problème technique.</b></div>';
			}, "json").always(function(data){
				$(myFormDiv+' #deleter').attr("disabled", false);
				$(myFormDiv+' #successfail').empty().append(returns);
			});
		}
	},
	table2Excel: function (tableId) {
		let exportInstance = $('#'+tableId).tableExport({
			headers: true, // (Boolean), display table headers (th or td elements) in the <thead>, (default: true)
			footers: false, // (Boolean), display table footers (th or td elements) in the <tfoot>, (default: false)
			formats: ["xlsx"], // (String[]), filetype(s) for the export, (default: ['xlsx', 'csv', 'txt'])
			filename: "export", // (id, String), filename for the downloaded file, (default: 'id')
			bootstrap: false, // (Boolean), style buttons using bootstrap, (default: true)
			exportButtons: false, // (Boolean), automatically generate the built-in export buttons for each of the specified formats (default: true)
			position: "bottom", // (top, bottom), position of the caption element relative to table, (default: 'bottom')
			ignoreRows: null, // (Number, Number[]), row indices to exclude from the exported file(s) (default: null)
			ignoreCols: null, // (Number, Number[]), column indices to exclude from the exported file(s) (default: null)
			trimWhitespace: true, // (Boolean), remove all leading/trailing newlines, spaces, and tabs from cell text in the exported file(s) (default: false)
			RTL: false, // (Boolean), set direction of the worksheet to right-to-left (default: false)
			sheetname: "export" // (id, String), sheet name for the exported spreadsheet, (default: 'id')
		});
		//globals.exportData = exportInstance.getExportData()
		let exportData = exportInstance.getExportData()[tableId]['xlsx'];
		exportInstance.export2file(exportData.data, exportData.mimeType, exportData.filename, exportData.fileExtension, exportData.merges, exportData.RTL, exportData.sheetname);
	},
	table2Csv: function (tableId) {
		globals.exportInstance = $('#'+tableId).tableExport({
			headers: true, // (Boolean), display table headers (th or td elements) in the <thead>, (default: true)
			footers: false, // (Boolean), display table footers (th or td elements) in the <tfoot>, (default: false)
			formats: ["csv"], // (String[]), filetype(s) for the export, (default: ['xlsx', 'csv', 'txt'])
			filename: "export", // (id, String), filename for the downloaded file, (default: 'id')
			bootstrap: false, // (Boolean), style buttons using bootstrap, (default: true)
			exportButtons: false, // (Boolean), automatically generate the built-in export buttons for each of the specified formats (default: true)
			position: "bottom", // (top, bottom), position of the caption element relative to table, (default: 'bottom')
			ignoreRows: null, // (Number, Number[]), row indices to exclude from the exported file(s) (default: null)
			ignoreCols: null, // (Number, Number[]), column indices to exclude from the exported file(s) (default: null)
			trimWhitespace: true, // (Boolean), remove all leading/trailing newlines, spaces, and tabs from cell text in the exported file(s) (default: false)
			RTL: false, // (Boolean), set direction of the worksheet to right-to-left (default: false)
			sheetname: "export" // (id, String), sheet name for the exported spreadsheet, (default: 'id')
		});
		//globals.exportData = exportInstance.getExportData()
		globals.exportData = globals.exportInstance.getExportData()[tableId]['csv'];
	},
	copyToClipboard: function(containerid) {
		var el = document.getElementById(containerid);
        var body = document.body, range, sel;
        if (document.createRange && window.getSelection) {
            range = document.createRange();
            sel = window.getSelection();
            sel.removeAllRanges();
            try {
                range.selectNodeContents(el);
                sel.addRange(range);
            } catch (e) {
                range.selectNode(el);
                sel.addRange(range);
            }
            document.execCommand("copy");
			alert("Tableau copié, vous pouvez le coler dans un document Excel par exemple.");
        } else if (body.createTextRange) {
            range = body.createTextRange();
            range.moveToElementText(el);
            range.select();
            range.execCommand("Copy");
			alert("Tableau copié, vous pouvez le coler dans un document Excel par exemple.");
		}
	},
	smoothScrollTo: function(target, offset) {
		// Smooth Scroll to div...
        if($(target).length) {
            $('html, body').stop().animate({
                'scrollTop': $(target).offset().top - offset
            }, 1600, 'swing', function() {
                // window.location.hash = target;
            });
        }
	},
	//below taken from http://www.howtocreate.co.uk/tutorials/javascript/browserwindow
	getScrollXY: function () {
		var scrOfX = 0, scrOfY = 0;
		if( typeof( window.pageYOffset ) == 'number' ) {
			//Netscape compliant
			scrOfY = window.pageYOffset;
			scrOfX = window.pageXOffset;
		} else if( document.body && ( document.body.scrollLeft || document.body.scrollTop ) ) {
			//DOM compliant
			scrOfY = document.body.scrollTop;
			scrOfX = document.body.scrollLeft;
		} else if( document.documentElement && ( document.documentElement.scrollLeft || document.documentElement.scrollTop ) ) {
			//IE6 standards compliant mode
			scrOfY = document.documentElement.scrollTop;
			scrOfX = document.documentElement.scrollLeft;
		}
		return [ scrOfX, scrOfY ];
	},

	//taken from http://james.padolsey.com/javascript/get-document-height-cross-browser/
	getDocHeight: function () {
		var D = document;
		return Math.max(
			D.body.scrollHeight, D.documentElement.scrollHeight,
			D.body.offsetHeight, D.documentElement.offsetHeight,
			D.body.clientHeight, D.documentElement.clientHeight
		);
	}
};

// Expose App object to window object => need this to use click event in html
window.App = App;

(function() {

	// If not logged in and page is not login redirects to login page...
	if ($.localStorage.getItem('pass')!='OK' && document.URL.indexOf( 'login.html' ) === -1)
	{
		document.location.href='login.html';
	}
	else {
		// pass ok or ko but it's login page.
		App.init();
	}

})();